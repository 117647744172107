import { render, staticRenderFns } from "./VList.vue?vue&type=template&id=a60d38ca"
import script from "./VList.vue?vue&type=script&lang=js"
export * from "./VList.vue?vue&type=script&lang=js"
import style0 from "./VList.vue?vue&type=style&index=0&id=a60d38ca&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports