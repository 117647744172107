import { sendTelemetryApi } from '@/api'

export default {
  name: 'Restriction',

  abstract: true,

  render: () => null,

  inject: [
    'service'
  ],

  data () {
    return {
      /**
       * @property {Boolean} restrictions - параметры возможных ограничений
       * @property {Boolean} restrictions[restrictionName].isActive - включён, или нет
       * @property {string} restrictions[restrictionName].eventName - на какой event вешать событие
       *
       * @property {Boolean} restrictions.doPrint - отключение возможности распечатать
       * @property {Boolean} restrictions.doPrintScreen - отключение кнопки PrintScreen
       * @property {Boolean} restrictions.goBack - отключение кнопки назад в браузере
       * @property {Boolean} restrictions.goOffScreen - запрет прехода на другую вкладку
       * @property {Boolean} restrictions.showContextMenu - отключение показа контекстного меню
       * @property {Boolean} restrictions.showDevTools - отключение вызова DevTools
       */
      restrictions: {
        goBack: {
          isActive: this.service.restrictions.goBack,
          eventName: 'popstate'
        },
        showContextMenu: {
          isActive: this.service.restrictions.showContextMenu,
          eventName: 'contextmenu'
        },
        showDevTools: {
          isActive: this.service.restrictions.showDevTools,
          eventName: 'keydown'
        },
        doPrintScreen: {
          isActive: this.service.restrictions.doPrintScreen,
          eventName: 'keyup'
        },
        doPrint: {
          isActive: this.service.restrictions.doPrint,
          eventName: 'keydown'
        },
        goOffScreen: {
          isActive: this.service.restrictions.goOffScreen,
          eventName: 'blur'
        }
      },
      /**
       * Шаблон сообщения в модальном окне
       * @param {string} html - текст сообщения
       * @return {[
       *  {type: string, content: string},
       *  {type: string, content},
       *  {type: string, content: string}
       * ]}
       */
      modalTemplate: html => ([
        { type: 'header', content: this.$t('restriction.modal.title') },
        { type: 'html', content: html },
        { type: 'submit', content: this.$t('restriction.modal.submit') }
      ]),
      /**
       * @property {object} mask - белая маска для защиты от копирования
       * @property {HTMLDivElement} mask.el - созданный элемент маски
       * @property {boolean} mask.isShow - показана в данный момент или нет
       * @property {number} mask.duration - время в ms сколько будет показываться
       */
      mask: {
        el: null,
        isShow: false,
        duration: 2000
      }
    }
  },

  watch: {
    /**
     * Отслеживание изменений какие ограничения нужны, какие нет
     */
    'service.restrictions': {
      deep: true,
      handler () {
        Object.keys(this.service.restrictions).forEach(name => {
          if (!this.restrictions[name]) return // бывали случаи, называли неверно

          if (this.restrictions[name].isActive !== this.service.restrictions[name]) {
            this.removeRestriction(name)
            this.restrictions[name].isActive = this.service.restrictions[name]
            this.addRestriction(name)
          }
        })
      }
    }
  },

  mounted () {
    this.addRestrictions()
  },

  destroyed () {
    this.removeRestrictions()
  },

  methods: {
    /**
     * Активировать все нужные ограничения
     */
    addRestrictions () {
      Object.keys(this.restrictions).forEach(name => this.addRestriction(name))
    },

    /**
     * Активировать ограничение
     * @param {string} name - имя ограничения
     */
    addRestriction (name) {
      if (!this.restrictions[name].isActive) return
      if (name === 'goBack') this.clearBrowserHistory()

      window.addEventListener(this.restrictions[name].eventName, this[`${name}Handler`])
    },

    /**
     * Деактивировать все ограничения
     */
    removeRestrictions () {
      Object.keys(this.restrictions).forEach(name => this.removeRestriction(name))
    },

    /**
     * Убрать ограничение
     * @param {string} name - имя ограничения
     */
    removeRestriction (name) {
      if (!this.restrictions[name].isActive) return
      window.removeEventListener(this.restrictions[name].eventName, this[`${name}Handler`])
    },

    /**
     * Действия при попытке нажать кнопку "Назад"
     * @param {PopStateEvent} event - объект события popstate
     */
    goBackHandler (event) {
      this.stopAnyEvents(event)
      this.clearBrowserHistory()

      if (this.service.showRestrictionModal) {
        this.service.modal.showModal(this.modalTemplate(this.$t('restriction.message.goBack')))
      }

      sendTelemetryApi({
        session_uuid: this.service.event,
        events: ['back']
      })
    },

    /**
     * Действия при попытке нажать на правую кнопку мыши
     * @param {PointerEvent} event - объект события contextmenu
     */
    showContextMenuHandler (event) {
      this.stopAnyEvents(event)
    },

    /**
     * Действия попытке воспользоваться DevTools
     * @param {KeyboardEvent} event - событие кнопки
     */
    showDevToolsHandler (event) {
      if (
        (event.key === 'F12') ||
        (event.ctrlKey && event.shiftKey && event.key === 'I')
      ) {
        this.stopAnyEvents(event)
      }
    },

    /**
     * Действия при попытке сделать скриншот
     * @param {KeyboardEvent} event
     */
    doPrintScreenHandler (event) {
      if (event.key === 'PrintScreen') {
        this.stopAnyEvents(event)
        this.showMask()

        sendTelemetryApi({
          session_uuid: this.service.event,
          events: ['print_screen']
        })

        this.doAfterMaskHidden(() => {
          if (this.service.showRestrictionModal) {
            this.service.modal.showModal(this.modalTemplate(this.$t('restriction.message.doPrintScreen')))
          }
        })
      }
    },

    /**
     * Действия при попытке распечатать страницу
     * @param {KeyboardEvent} event - объект события нажатия на кнопку
     */
    doPrintHandler (event) {
      if (
        (event.ctrlKey && event.key === 'p') ||
        (event.ctrlKey && event.shiftKey && event.key === 'p')
      ) {
        this.stopAnyEvents(event)
        this.showMask()
        this.doAfterMaskHidden(() => {
          if (this.service.showRestrictionModal) {
            this.service.modal.showModal(this.modalTemplate(this.$t('restriction.message.doPrint')))
          }
        })
      }
    },

    /**
     * Действия при попытке сменить окно браузера (window.blur)
     * @param {Event} event - объект события blur
     */
    goOffScreenHandler (event) {
      this.stopAnyEvents(event)

      console.log(this.service.showRestrictionModal)
      if (this.service.showRestrictionModal) {
        this.service.modal.showModal(this.modalTemplate(this.$t('restriction.message.goOffScreen')))
      }

      sendTelemetryApi({
        session_uuid: this.service.event,
        events: ['lost_focus']
      })
    },

    clearBrowserHistory () {
      history.pushState(null, null, location.href)
    },

    /**
     * Запрет всплытия событий и событий по умолчанию
     * @param {*} event - событие
     */
    stopAnyEvents (event) {
      this.$nextTick(() => {
        event.preventDefault()
        event.stopImmediatePropagation()
      })
    },

    /**
     * Создание маски (белый div на весь экран)
     */
    createMask () {
      const div = document.createElement('div')

      div.style.position = 'fixed'
      div.style.backgroundColor = 'white'
      div.style.top = '0'
      div.style.right = '0'
      div.style.bottom = '0'
      div.style.left = '0'
      div.style.zIndex = '999999'

      this.mask.el = div
    },

    /**
     * Показать маску
     */
    showMask () {
      navigator.clipboard.writeText('')

      if (this.mask.isShow) return

      if (!this.mask.el) {
        this.createMask()
      }

      document.body.appendChild(this.mask.el)
      this.doAfterMaskHidden(this.hideMask)
      this.mask.isShow = true
    },

    /**
     * Скрыть маску
     */
    hideMask () {
      document.body.removeChild(this.mask.el)
      this.mask.isShow = false
    },

    /**
     * действия после того как маска была скрыта
     * @param callback
     */
    doAfterMaskHidden (callback) {
      setTimeout(callback, this.mask.duration)
    }
  }
}
