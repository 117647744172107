<template>
  <th
    :class="classes"
    class="v-table-th"
  >
    <span
      v-if="vertical"
      class="v-table-th__text"
    >
      <slot></slot>
    </span>

    <slot v-else></slot>
  </th>
</template>

<script>
const POSITION = ['top', 'left']

export default {
  name: 'VTableTh',

  props: {
    active: Boolean,
    vertical: Boolean,
    sticky: {
      type: String,
      validator: (position) => POSITION.includes(position)
    }
  },

  computed: {
    classes () {
      return {
        'v-table-th_is_active': this.active,
        'v-table-th_is_vertical': this.vertical,
        [`v-table-th_sticky_${this.sticky}`]: this.sticky
      }
    }
  }
}
</script>

<style lang="scss">
.v-table-th {
  //min-width: 6rem;
  padding: .5rem .75rem;
  font-size: .75rem;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  border-bottom: 1px solid var(--color-border);

  &:not(:last-of-type) {
    border-right: 1px solid var(--color-border);
  }

  &_is {
    &_first {
      width: 100%;
      text-align: left;
    }

    &_active {
      background-color: var(--color-background-2);
    }

    &_vertical {
      min-width: auto;
      width: auto;
      vertical-align: bottom;
      text-align: center;
      background-color: inherit;

      .v-table-th__text {
        display: inline-block;
        max-height: 8rem; // Safari
        min-height: 8rem; // Safari
        text-align: left;
        // Safari: writing-mode в связке с rotate ведёт себя примерно как position absolute
        writing-mode: vertical-rl;
        transform: rotate(180deg);
        line-height: 1;
      }
    }
  }

  &_sticky {
    &_top,
    &_left {
      z-index: 1;
      position: sticky;
      background-color: inherit;
    }

    &_top {
      top: 0;
    }

    &_left {
      left: 0;
    }
  }
}
</style>
