<template>
  <td
    :class="classes"
    class="v-table-td"
  >
    <slot></slot>
  </td>
</template>

<script>
export default {
  name: 'VTableTd',

  props: {
    active: Boolean
  },

  computed: {
    classes () {
      return {
        'v-table-td_is_active': this.active
      }
    }
  }
}
</script>

<style lang="scss">
@import "~@styles/variables";
@import "~@styles/tools";

.v-table-td {
  height: 100%;
  padding: .5rem .75rem;

  &:not(:last-of-type) {
    border-right: 1px solid cl(border);
  }

  &_is {
    &_active {
      background-color: var(--v-table-active-color);
    }
  }
}
</style>
