import Vue from 'vue'
import VueRouter from 'vue-router'
import { routerGuide, routerMain } from './routes'

const { BASE_URL, NODE_ENV } = process.env

Vue.use(VueRouter)

const withGuide = (isDev = false) => {
  const guidRoutes = isDev
    ? routerGuide
    : []

  return [
    ...routerMain,
    ...guidRoutes,
    {
      path: '/',
      name: 'WarningPage',
      component: () => import('@/views/Warnings/WarningRoute')
    },
    {
      path: '/*',
      name: 'Processing',
      component: () => import('@/views/Processing/Processing')
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
}

const router = new VueRouter({
  mode: 'history',
  base: BASE_URL,
  routes: withGuide(NODE_ENV === 'development')
})

export default router
