import { convertHexToRgb } from '@utils/helpers'

/**
 * Get text color depend of background color
 * @param {number | string} bgHexColor - background hex color
 * @return {boolean} - black or white
 */
export default function getTextColor (bgHexColor) {
  const rgbValue = convertHexToRgb(bgHexColor)
  const color = Math.round((
    (rgbValue[0] * 299) +
    (rgbValue[1] * 587) +
    (rgbValue[2] * 114)
  ) / 1000)

  return (color > 125)
}
