<template>
    <svg
      :viewBox="viewBox"
      :aria-labelledby="name"
      :width="width"
      :height="height"
      :fill="fill"
      :stroke="stroke"
      :title="title"
      class="v-icon-svg"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <slot />
    </svg>
</template>

<script>
/**
 * !!! DEPRICATED !!!
 * Use VIcon component
 **/
export default {
  name: 'VIconSvg',

  props: {
    name: String,
    title: String,
    width: String,
    height: String,

    fill: {
      type: String,
      default: 'none'
    },
    stroke: {
      type: String,
      default: 'none'
    },
    viewBox: {
      type: String,
      default: '0 0 16 16'
    }
  }
}
</script>

<style lang="scss">
  .v-icon-svg {
    position: relative;
    display: inline-block;
    padding: 0;
    vertical-align: middle;
    top: -2px;
  }
</style>
