<template>
  <section class="main-container">
    <header class="main-container__header">
      <slot name="header"></slot>
    </header>

    <main class="main-container__content">
      <slot></slot>
    </main>

    <footer class="main-container__footer">
      <slot name="footer"></slot>
    </footer>
  </section>
</template>

<script>
export default {
  name: 'LayoutMain'
}
</script>

<style lang="scss" scoped>
@import "~@styles/variables";
@import "~@styles/tools";

.main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;

  &__header {
    background-color: #FFFFFF;
    border-bottom: 1px solid cl(ui-3);
  }
  &__content {
    flex-grow: 1;
    flex-shrink: 0;
    background-color: cl(background-1);
  }
  &__footer {
    background-color: #FFFFFF;
    border-top: 1px solid cl(ui-3);
  }
}
</style>
