import { getType } from '@utils/helpers/index'

export const getUrlParams = (url = window.location.search) => {
  return url
    ? (/^[?#]/.test(url) ? url.slice(1) : url)
      .split('&')
      .reduce((params, param) => {
          const [key, value] = param.split('=')
          const preparedValue = value
            ? decodeURIComponent(value.replace(/\+/g, ' '))
            : null
          const paramType = getType(params[key])

          if (!preparedValue) {
            return params
          }

          if (paramType === 'string') {
            params[key] = [
              params[key],
              preparedValue
            ]
          } else if (paramType === 'array') {
            params[key] = [
              ...params[key],
              preparedValue
            ]
          } else {
            params[key] = preparedValue
          }

          return params
        }, {}
      )
    : {}
}
