<template>
  <l-modal-base class="p-32">
    <template #header>
      <h3
        v-if="title"
        v-html="title"
        class="mb-24"
      />
    </template>

    <template #default>
      <div
        v-if="subject"
        v-html="subject"
      />
    </template>

    <template #footer>
      <div class="row mt-24">
        <div class="col-auto">
          <v-button
            color="primary"
            @click="cancelHandler"
          >
            {{ cancelBtnText }}
          </v-button>
        </div>
        <div class="col-auto">
          <v-button
            color="primary"
            transparent
            @click="submitHandler"
          >
            {{ submitBtnText }}
          </v-button>
        </div>
      </div>
    </template>
  </l-modal-base>
</template>

<script>
import LModalBase from '@/layouts/LModal/LModalBase'
import { VButton } from '@/components/base'
import { mapActions } from 'vuex'

export default {
  name: 'MSubmitConfirmation',
  props: {
    title: String,
    subject: String,
    submitBtnText: {
      type: String,
      default: 'OK'
    },
    cancelBtnText: {
      type: String,
      default: 'Отмена'
    },
    reversButtons: Boolean
  },
  components: {
    VButton,
    LModalBase
  },
  methods: {
    ...mapActions('modal', [
      'hideModal'
    ]),
    submitHandler () {
      this.$emit('submit')
      this.hideModal()
    },
    cancelHandler () {
      this.$emit('cancel')
      this.hideModal()
    }
  }
}
</script>
