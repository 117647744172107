import { store } from '@/store'

export default class ShowMessageOnTimer {
  _timer = null;

  constructor (
    message = 'Ваше Интернет-соединение нестабильно. Пожалуйста, подождите.',
    duration = 10000,
    snackbarOptions = { endless: true }
  ) {
    this.duration = duration
    this.message = message
    this.snackbarOptions = snackbarOptions
  }

  start (message, duration) {
    this._clearTimer()
    this._setTimer()

    if (message) {
      this.setMessage(message)
    }

    if (duration) {
      this.setDuration(duration)
    }
  }

  stop () {
    this._clearTimer()
    this._hideWarningMessage()
  }

  setMessage (message) {
    this.message = message
  }

  setDuration (duration) {
    this.duration = duration
  }

  _setTimer () {
    this._timer = window.setTimeout(this._showWarningMessage.bind(this), this.duration)
  }

  _clearTimer () {
    window.clearTimeout(this._timer)
    this._timer = null
  }

  _showWarningMessage () {
    store.dispatch('snackbar/showSnackbar', {
      content: this.message,
      options: this.snackbarOptions
    })
  }

  _hideWarningMessage () {
    store.dispatch('snackbar/hideSnackbar')
  }
}
