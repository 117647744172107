<template>
  <div
    :style="styles"
    :class="classes"
    :aria-valuenow="value"
    class="v-progress-circular"
    aria-valuemin="0"
    aria-valuemax="100"
    role="progressbar"
  >
    <svg
      :style="this.svgStyles"
      :viewBox="`${this.viewBoxSize} ${this.viewBoxSize} ${2 * this.viewBoxSize} ${2 * this.viewBoxSize}`"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        :cx="2 * this.viewBoxSize"
        :cy="2 * this.viewBoxSize"
        :r="this.radius"
        :stroke-width="this.strokeWidth"
        :stroke-dasharray="this.strokeDashArray"
        stroke-dashoffset="0"
        fill="transparent"
        class="v-progress-circular__underlay"
      ></circle>
      <circle
        :cx="2 * this.viewBoxSize"
        :cy="2 * this.viewBoxSize"
        :r="this.radius"
        :stroke-width="this.strokeWidth"
        :stroke-dasharray="this.strokeDashArray"
        :stroke-dashoffset="this.strokeDashOffset"
        fill="transparent"
        class="v-progress-circular__overlay"
      ></circle>
    </svg>
  </div>
</template>

<script>
import { convertToUnit } from '@utils/helpers'

export default {
  name: 'VProgressCircular',

  props: {
    indeterminate: Boolean,
    rotate: {
      type: [Number, String],
      default: 0
    },
    size: {
      type: [Number, String],
      default: 32
    },
    width: {
      type: [Number, String],
      default: 4
    },
    value: {
      type: [Number, String],
      default: 0
    }
  },

  data: () => ({
    radius: 20
  }),

  computed: {
    calculatedSize () {
      return Number(this.size) + (this.button ? 8 : 0)
    },

    circumference () {
      return 2 * Math.PI * this.radius
    },

    classes () {
      return {
        'v-progress-circular_indeterminate': this.indeterminate
      }
    },

    normalizedValue () {
      if (this.value < 0) {
        return 0
      }

      if (this.value > 100) {
        return 100
      }

      return parseFloat(this.value)
    },

    strokeDashArray () {
      return Math.round(this.circumference * 1000) / 1000
    },

    strokeDashOffset () {
      return ((100 - this.normalizedValue) / 100) * this.circumference + 'px'
    },

    strokeWidth () {
      return Number(this.width) / +this.size * this.viewBoxSize * 2
    },

    styles () {
      return {
        height: convertToUnit(this.calculatedSize),
        width: convertToUnit(this.calculatedSize)
      }
    },

    svgStyles () {
      return {
        transform: `rotate(${Number(this.rotate)}deg)`
      }
    },

    viewBoxSize () {
      return this.radius / (1 - Number(this.width) / +this.size)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@styles/variables";
@import "~@styles/tools";

$progress-circular-rotate-animation: progress-circular-rotate 1.4s linear infinite !default;
$progress-circular-rotate-dash: progress-circular-dash 1.4s ease-in-out infinite !default;
$process-circular-intermediate-svg-transition: all .2s ease-in-out !default;
$progress-circular-underlay-stroke: rgba(cl(rgb-primary), 0.2) !default;
$progress-circular-overlay-transition: all .6s ease-in-out !default;

.v-progress-circular {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  justify-content: center;
  align-items: center;

  > svg {
    width: 100%;
    height: 100%;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }

  &__info {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  &__underlay {
    stroke: $progress-circular-underlay-stroke;
    z-index: 1;
  }

  &__overlay {
    stroke: var(--color-primary);
    z-index: 2;
    transition: $progress-circular-overlay-transition;
  }

  &_indeterminate {
    > svg {
      animation: $progress-circular-rotate-animation;
      transform-origin: center center;
      transition: $process-circular-intermediate-svg-transition;
    }

    .v-progress-circular__overlay {
      animation: $progress-circular-rotate-dash;
      stroke-linecap: round;
      stroke-dasharray: 80, 200;
      stroke-dashoffset: 0;
    }
  }
}

@keyframes progress-circular-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -15px;
  }

  100% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -125px;
  }
}

@keyframes progress-circular-rotate {
  100% {
    transform: rotate(360deg);
  }
}

</style>
