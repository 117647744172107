<template>
  <l-linear-choice>
    <template #textLeft>
      <v-sheet
        :value="choice === 1"
        :ripple="false"
        :color="color"
        outlined
        rounded
        class="p-4"
      >
        <slot name="textLeft"/>
      </v-sheet>
    </template>

    <template #default>
      <slot/>
    </template>

    <template #textRight>
      <v-sheet
        :value="choice === 2"
        :ripple="false"
        :color="color"
        outlined
        rounded
        class="p-4"
      >
        <slot name="textRight"/>
      </v-sheet>
    </template>
  </l-linear-choice>
</template>

<script>
import { VSheet } from '@components/base'
import { LLinearChoice } from '@layouts'
import { Colorable } from '@mixins'

export default {
  name: 'LinearChoice',

  components: {
    VSheet,
    LLinearChoice
  },

  mixins: [
    Colorable
  ],

  props: {
    value: String,
    options: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    choice () {
      const index = this.options.findIndex(item => item.code === this.value)
      const average = this.options.length / 2

      if (index === -1) {
        return null
      } else if (index < average) {
        return 1
      } else {
        return 2
      }
    }
  }
}
</script>
