const fullNumber = (val) => {
  val = Math.floor(val)
  return val < 10 ? `0${val}` : val
}
/**
 * Переводит сикунды в читаемый формат -> 01:12
 * @param {number} totalSeconds - количество секунд
 * @param {string} delimiter - разделитель
 *
 * @example
 * // returns 11:06
 * toTimeFormat(666)
 *
 * @example
 * // returns 01.51.06
 * toTimeFormat(6666, '.')
 *
 * @returns {string} - отформатирование время
 */
const secToTime = (totalSeconds, delimiter = ':') => {
  const hours = fullNumber(totalSeconds / 3600 % 24)
  const minutes = fullNumber(totalSeconds / 60 % 60)
  const seconds = fullNumber(totalSeconds % 60)

  return totalSeconds >= 3600
    ? `${hours}${delimiter}${minutes}${delimiter}${seconds}`
    : `${minutes}${delimiter}${seconds}`
}

export default secToTime
