import { EL_SIZES, SIZES } from '@/constants/components'
import { convertCamelToKebab } from '@utils/helpers'

export default {
  props: {
    size: {
      type: String,
      validator: (type) => EL_SIZES.includes(type) || SIZES.includes(type)
    }
  },

  computed: {
    sizeClasses () {
      return {
        [`${convertCamelToKebab(this.$options.name)}_size_${this.size}`]: !!this.size
      }
    }
  }
}
