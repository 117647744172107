import { THEMES } from '@constants/components'

const defaultState = {
  current: 'default',
  all: THEMES
}

export default {
  namespaced: true,

  state: { ...defaultState },

  mutations: {
    SET_THEME (state, themeName) {
      state.current = themeName
    }
  },

  actions: {
    setTheme ({ commit, state }, themeName) {
      if (!state.all.includes(themeName)) {
        return new Error(`Темы с именем ${themeName} не существует!`)
      }

      commit('SET_THEME', themeName)
    }
  }
}
