<template>
  <r-portal>
    <transition name="snackbar-animation">
      <section
        v-if="value"
        :class="classes"
        class="v-snackbar"
      >
        <div class="v-snackbar__container">
          <div
            v-mutate="resetSnackbar"
            class="v-snackbar__content"
          >
            <slot/>
          </div>
          <div class="v-snackbar__action">
            <slot name="action"/>
          </div>
        </div>
      </section>
    </transition>
  </r-portal>
</template>

<script>
import { Mutate } from '@directives'
import { RPortal } from '@components/rednderless'
import { Colorable } from '@/mixins'
import { POSITION_X } from '@constants/components'

export default {
  name: 'VSnackbar',

  directives: {
    Mutate
  },

  components: {
    RPortal
  },

  mixins: [Colorable],

  props: {
    endless: Boolean,
    position: {
      type: String,
      validator: position => POSITION_X.includes(position)
    },
    timeout: {
      type: Number,
      default: 5000
    },
    value: Boolean
  },

  data () {
    return {
      timer: null
    }
  },

  computed: {
    classes () {
      return {
        [`v-snackbar_position_${this.position}`]: !!this.position,
        ...this.colorClasses
      }
    }
  },

  watch: {
    /**
     * Управление закрытием снэкбара по таймеру
     * @param {Boolean} isShow - состояние отображения снэкбара
     */
    value (isShow) {
      if (isShow && !this.endless) {
        this.timer = setTimeout(this.hideSnackbar, this.timeout)
      } else {
        this.timer = null
      }
    }
  },

  methods: {
    hideSnackbar () {
      this.$emit('input', false)
    },

    showSnackbar () {
      this.$emit('input', true)
    },

    resetSnackbar () {
      this.hideSnackbar()

      clearTimeout(this.timer)
      this.timer = null

      setTimeout(this.showSnackbar, 100)
    }
  }
}
</script>

<style lang="scss">
@import "~@styles/variables";
@import "~@styles/tools";

.v-snackbar {
  @include z-index(snackbar);
  position: fixed;
  bottom: 0;
  padding: .5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  transition: opacity 2s ease;

  &__container {
    display: flex;
    align-items: center;
    padding: .5rem 1rem;
    margin-top: .5rem;
    color: #FFFFFF;
    font-size: .75rem;
    background-color: #333;
    @include elevation(2);
    @include radius(null)
  }

  &_position {
    &_left {
      justify-content: flex-start;
    }

    &_right {
      justify-content: flex-end;
    }
  }

  &_color {
    @each $color in map-keys($theme-colors) {
      &_#{$color} {
        > .v-snackbar__container {
          background-color: cl($color);
        }
      }
    }
  }
}

.snackbar-animation {
  &-enter-active {
    opacity: 1;
  }

  &-leave-active {
    opacity: 0;
  }
}
</style>
