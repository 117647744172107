import { i18n } from '@/i18n'

/**
 * Конвертирует timestamp в формат типа 1 час 12 минут 5 секунд
 * @param {number|string} time - время в секундах
 * @returns {string} - время в заданном формате
 */
export default function convertTimestampToTime (time) {
  const seconds = Math.floor(+time % 60)
  const minutes = Math.floor((+time / 60) % 60)
  const hours = Math.floor((+time / 3600) % 24)

  const secondsString = i18n.tc('site.time.seconds', seconds)
  const minutesString = i18n.tc('site.time.minutes', minutes)
  const hoursString = i18n.tc('site.time.hours', hours)

  let fullTime = ''

  if (hours) {
    fullTime = `${hoursString}`
  }

  if (minutes) {
    fullTime = `${fullTime} ${minutesString}`
  }

  if (seconds) {
    fullTime = `${fullTime} ${secondsString}`
  }

  return fullTime
}
