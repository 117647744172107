import axios from 'axios'
import router from '@/router'
import { ShowMessageOnTimer } from '@/services'
import { i18n } from '@/i18n'
import { store } from '@/store'
import vueInstance from '@/main'
import { getUrlParams } from '@utils/helpers'

const { VUE_APP_API_URL } = process.env
const apiAxios = axios.create({
  baseURL: `${VUE_APP_API_URL}`
})
// Уведомление пользователя в случае долгого соединения
const showMessageOnTimer = new ShowMessageOnTimer()
const setMessage = (message = i18n.t('site.message.goToTestLink')) => {
  vueInstance.error.message = message
}

apiAxios.interceptors.request.use(config => {
  const token = localStorage.getItem('x-auth-token')
  const urlParams = getUrlParams()

  config.headers.Authorization = token
    ? `Bearer ${token}`
    : ''
  showMessageOnTimer.start()

  if (config.url === 'content' && store.state.language.current) {
    config.headers['Content-Language'] = store.state.language.current
  } else {
    delete config.headers['Content-Language']
  }

  if (config.url === 'session') {
    /**
     * добавление utm меток
     * @link https://ecopsy.atlassian.net/wiki/spaces/LKS/pages/2206531593/-#UTM-%D0%BC%D0%B5%D1%82%D0%BA%D0%B8
     */

    Object.entries(urlParams).forEach(([key, value]) => {
      const whiteList = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']

      if (whiteList.includes(key)) {
        config.data.append(key, value)
      }
    })
  }

  return config
})

apiAxios.interceptors.response.use(
  (response) => {
    showMessageOnTimer.stop()

    // Меняем язык только если данные из /content
    if (response.config.url === 'content') {
      store
        .dispatch('language/setCurrent', { language: response.headers['content-language'] })
        .then(() => showMessageOnTimer.setMessage(i18n.t('site.error.request.internetConnectionIsUnstable')))
        .then(() => setMessage())
    }

    return response
  },
  (error) => {
    showMessageOnTimer.stop()

    // зачистка предыдущей ошибки
    setMessage()

    if (error?.response) {
      const { status, data, config } = error.response

      /**
       * Отключено в связи с непредвиденными переходами пользователей на страницу логина
       * https://ecopsy.atlassian.net/browse/LNKS-2364
       */
      // if (status === 401) {
      //   return router
      //     .push(`${VUE_APP_AUTH_PATH}`)
      //     .then(() => Promise.reject(error))
      // }

      if (
        config.url === 'session' &&
        (
          status === 404 ||
          status === 500
        )
      ) {
        setMessage(data.message || i18n.t('site.error.request.onServer'))

        if (router.currentRoute.path !== '/') {
          return router
            .push('/')
            .then(() => Promise.reject(error))
        }
      } else if (
        data?.message &&
        status === 500
      ) {
        store.dispatch('snackbar/showSnackbar', {
          content: data.message,
          options: { color: 'danger' }
        })
        console.error(data.message)
      }
    }

    return Promise.reject(error)
  }
)

// auth
export const getAuthToken = data => apiAxios.post('respondent_login', data)

// processing
export const getSessionApi = data => apiAxios.post('session', data)
export const getContentApi = data => apiAxios.post('content', data)
export const getContentAjaxApi = data => apiAxios.post('content_ajax', data)
export const sendContentApi = data => apiAxios.post('response', data)

// check
export const checkAjaxApi = config => apiAxios.get('load', config)
export const sendTelemetryApi = data => apiAxios.post('telemetry', data)

// screen360
export const getSessionStatusesApi = session => apiAxios.get('session_statuses', { params: { session } })
export const createRespondentPairBySession = data => apiAxios.post('create_respondent_pair_by_session', data)

// evraz
export const changeRespondentAttribute = data => apiAxios.post('change_respondent_attribute', data)
export const routeTo = data => apiAxios.post('route_to', data)

// for dynamic logic
export const sendAnswersAndGetDynamicContent = (data, options) => apiAxios({
  url: 'nsadc',
  method: 'post',
  ...options,
  data
})

export default {
  getSessionApi,
  getContentApi,
  getContentAjaxApi,
  sendContentApi,

  checkAjaxApi,
  sendTelemetryApi,

  getSessionStatusesApi,
  createRespondentPairBySession,

  changeRespondentAttribute,
  routeTo,

  sendAnswersAndGetDynamicContent
}
