/**
 * Throttling decorator
 * @param {function} cb - callback function
 * @param {number} time - time step
 * @param {*} ctx - context
 * @return {(function(...[*]=): void)|*}
 */
export default function throttle (cb, time = 1000, ctx = null) {
  let timer = null
  return (...args) => {
    if (timer) {
      return
    }
    timer = setTimeout(() => {
      cb.apply(ctx, args)
      timer = null
    }, time)
  }
}
