export default {
  props: {
    elevation: [Number, String]
  },

  computed: {
    /**
     * @return {Number|String|undefined}
     */
    computedElevation () {
      return this.elevation
    },
    /**
     * @return {{}|{[p: string]: boolean}}
     */
    elevationClasses () {
      const elevation = this.computedElevation

      if (elevation == null) return {}
      if (isNaN(parseInt(elevation))) return {}
      return { [`elevation-${this.elevation}`]: true }
    }
  }
}
