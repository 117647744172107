/**
 * Append script to html head
 * @param {string} src - source url
 * @param {boolean} async - async or defer
 */
export default function addScriptToHead (src, async = false) {
  const script = document.createElement('script')
  script.async = async
  script.type = 'text/javascript'
  script.src = src
  document.head.appendChild(script)
}
