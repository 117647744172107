/**
 * Get the type of a variable
 * @param {*} value - value
 * @returns { string } - type of value
 * @example
 * https://gist.github.com/shchegol/97b3d06f28fb5fc4e503f6a18cc2ca9f
 */
export default function getType (value) {
  const regex = /^\[object (\S+?)]$/
  const matches = Object.prototype.toString.call(value).match(regex) || []

  return (matches[1] || 'undefined').toLowerCase()
}
