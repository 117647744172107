import { getType } from '@utils/helpers'

/**
 * add classes to body tag
 * @param {string[]} classNames - class names to add
 * @param {string} [prefix] - prefix name, if needed
 * @returns {boolean}
 */
export const addClassesToBody = (classNames, prefix) => {
  const classNamesType = getType(classNames)

  if (classNamesType !== 'array') {
    console.error('First argument in addClassesToBody function must be an array')
    return false
  }

  if (!classNames.length) {
    return true
  }

  if (prefix) {
    prefix = `${prefix}-`
  }

  classNames.forEach(mode => {
    document.body.classList.add(`${prefix}${mode}`)
  })

  return true
}

/**
 * Clear the classes specified in the classNames
 * @param {string[]} classNames - class names to remove from the body tag
 * @param {string} [prefix] - prefix name, if needed
 * @returns {boolean}
 */
export const clearBodyClassesByList = (classNames, prefix) => {
  const classNamesType = getType(classNames)

  if (classNamesType !== 'array') {
    console.error('First argument in clearBodyClassesByList function must be an array')
    return false
  }

  if (!classNames.length) {
    return true
  }

  if (prefix) {
    prefix = `${prefix}-`
  }

  classNames.forEach(themeName => {
    if (document.body.classList.contains(`${prefix}${themeName}`)) {
      document.body.classList.remove(`${prefix}${themeName}`)
    }
  })

  return true
}
