<template>
  <v-sheet
    :color="currentColor"
    :disabled="disabled"
    :value="isActive"
    hovered
    outlined
    rounded
    class="mb-8"
  >
    <v-checkbox
      :disabled="disabled"
      :model-value="modelValue"
      :value="value"
      @change="onChange"
      class="p-8"
    >
      <slot></slot>
    </v-checkbox>
  </v-sheet>
</template>

<script>
import { VCheckbox, VSheet } from '@components/base'

export default {
  name: 'CardButtonCheckbox',

  components: { VSheet, VCheckbox },

  model: {
    prop: 'modelValue',
    event: 'change'
  },

  props: {
    disabled: Boolean,
    error: Boolean,
    modelValue: {
      default: false,
      type: [Boolean, Array]
    },
    value: [String, Number]
  },

  computed: {
    isActive () {
      return typeof this.modelValue === 'boolean' ? this.modelValue : this.modelValue.includes(this.value)
    },
    currentColor () {
      return this.error
        ? 'danger'
        : null
    }
  },

  methods: {
    onChange (values, oldValues, name) {
      this.$emit('change', values, oldValues, name)
    }
  }
}
</script>
