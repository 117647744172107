/**
 * Create custom array
 * @callback valueTemplateCallback
 * @param {number} i - current index
 *
 * @param {number} [length=10] - length of array
 * @param {valueTemplateCallback} [template = (i) => i] - array item pattern
 * @return {*[]} - array of template values
 */
export default function createCustomArray ({ length = 10, template = (i) => i }) {
  return Array.from({ length: length }, (_, i) => template(i))
}
