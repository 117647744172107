/**
 * Документация
 * @link https://ecopsy.atlassian.net/wiki/spaces/LKS/pages/2010939395#%D0%9B%D0%BE%D0%B3%D0%B8%D0%BA%D0%B0-%D0%B2%D0%B0%D0%BB%D0%B8%D0%B4%D0%B0%D1%86%D0%B8%D0%B8-%D0%BF%D1%80%D0%BE%D1%81%D1%8B%D1%82%D1%85-%D0%B2%D0%BE%D0%BF%D1%80%D0%BE%D1%81%D0%BE%D0%B2-%D1%81-min%2Fmax
 */

export default {
  data () {
    return {
      minMaxSendDataPattern: sendDataValue => sendDataValue
    }
  },

  computed: {
    isMultipleMax () {
      return (this.sendData[this.content.code] && this.content.multiple_max > 0) &&
        this.content.multiple_max < this.minMaxSendDataPattern(this.sendData[this.content.code]).length
    },
    multiple_isGreaterOrEqual () {
      return (this.sendData[this.content.code] && this.content.multiple_max > 0) &&
        this.content.multiple_max <= this.minMaxSendDataPattern(this.sendData[this.content.code]).length
    },
    isMultipleMin () {
      return (this.sendData[this.content.code] && this.content.multiple_min > 0) &&
        this.content.multiple_min > this.minMaxSendDataPattern(this.sendData[this.content.code]).length
    }
  },

  methods: {
    checkMultipleMinMax () {
      const { multiple_min, multiple_max } = this.content

      if (
        (multiple_min > multiple_max && multiple_max > 0) || // невозможное выражение min > max
        (!this.content.required && !this.sendData[this.content.code]?.length) // необязательный вопрос и дан минимум один ответ
      ) return true

      if (multiple_min !== 0 && this.isMultipleMin) {
        this.service.validation.state[this.content.code] = false
        this.service.validation.filled[this.content.code] = false
        this.service.error.text = this.$tc('mixins.validatable.multiple.min', multiple_min)
        return false
      }

      if (multiple_max !== 0 && this.isMultipleMax) {
        this.service.validation.state[this.content.code] = false
        this.service.validation.filled[this.content.code] = false
        this.service.error.text = this.$tc('mixins.validatable.multiple.max', multiple_max)
        return false
      }

      return true
    }
  }
}
