export default {
  computed: {
    isValid () {
      return this.service.validation.state[this.content.code]
    }
  },

  mounted () {
    this.checkValidationByCondition()
  },

  methods: {
    checkValidationByCondition (condition = this.sendData[this.content?.code]?.length, message) {
      this.service.validation.filled[this.content.code] = !!condition

      if (this.content.inactive) return true

      this.service.error.text = message || this.$t('site.error.answerTheQuestion')
      this.service.validation.state[this.content.code] = !this.content.required || condition

      return this.service.validation.state[this.content.code]
    }
  }
}
