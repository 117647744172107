<template>
  <div
    :class="classes"
    class="audio-player"
  >
    <div class="audio-player__main">
      <div class="audio-player__controls">
        <v-button
          rounded="circle"
          size="sm"
          icon
          @click="togglePlay"
          class="audio-player__play-btn"
        >
          <v-icon-svg
            view-box="0 0 9 12"
            width="16"
            height="16"
          >
            <component :is="`SvgIcon360${currentIcon}`"/>
          </v-icon-svg>
        </v-button>
      </div>

      <div class="audio-player__progress">
        <v-slider
          v-model.number.trim="audio.time.current"
          :variants="variants"
          :line-height="4"
          :bullet-size="16"
          @drag-start="onDragStart"
          @drag-end="onDragEnd"
          @click="onClick"
          colors="#3054e8"
        />
      </div>

      <div class="audio-player__time">
        {{ currentTime }}
      </div>
    </div>

    <audio
      ref="audio"
      :src="file"
      style="display: none;"
      @loadeddata="onLoadedData"
      @play="onPlay"
      @pause="onPause"
      @timeupdate="onTimeUpdate"
    />
  </div>
</template>

<script>
import { secToTime } from '@filters'
import { Colorable, Sizeable } from '@mixins'
import {
  VButton,
  VSlider,
  VIconSvg
} from '@components/base'
import { createCustomArray } from '@utils/helpers'
import SvgIcon360Play from '@components/icons/360/SvgIcon360Play'
import SvgIcon360Pause from '@components/icons/360/SvgIcon360Pause'

export default {
  name: 'AudioPlayer',

  components: {
    SvgIcon360Pause,
    SvgIcon360Play,
    VButton,
    VIconSvg,
    VSlider
  },

  mixins: [Colorable, Sizeable],

  props: {
    file: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      variants: [{ id: 0 }],
      audio: {
        el: null,
        time: {
          current: 0,
          duration: 0
        }
      },
      isLoaded: false,
      isPlay: false
    }
  },

  computed: {
    classes () {
      return {
        'audio-player_is_play': this.isPlay
      }
    },
    currentIcon () {
      return this.isPlay
        ? 'Pause'
        : 'Play'
    },
    currentTime () {
      const { current, duration } = this.audio.time
      const difference = duration - current
      let minus = ''

      if (difference > 0 && difference !== duration) {
        minus = '−'
      }

      return `${minus}${this.secToTime(difference)}`
    }
  },

  mounted () {
    this.audio.el = this.$refs.audio
  },

  methods: {
    onDragStart () {
      this.onPause()
    },
    onDragEnd (time) {
      this.setTime(time)
      this.onPlay()
    },
    onClick (time) {
      this.setTime(time)
    },
    togglePlay () {
      this.isPlay
        ? this.onPause()
        : this.onPlay()
    },
    onPlay () {
      this.isPlay = true
      this.audio.el.play()
      this.$emit('play')
    },
    onPause () {
      this.isPlay = false
      this.audio.el.pause()
      this.$emit('pause')
    },
    onLoadedData () {
      if (this.audio.el.readyState < 2) throw new Error('Failed to load sound file.')

      this.isLoaded = true
      this.audio.time.duration = parseInt(this.audio.el.duration)

      this.variants = createCustomArray({
        length: this.audio.time.duration + 1,
        template: i => ({ id: i })
      })
    },
    onTimeUpdate () {
      const currentTime = parseInt(this.audio.el.currentTime)

      // конец трека
      if (currentTime >= this.audio.time.duration && this.isPlay) {
        this.onPause()
        this.audio.time.current = 0
        this.setTime(0)

        return
      }

      if (this.audio.time.current === currentTime) return

      this.audio.time.current = currentTime
    },
    setTime (time) {
      this.audio.el.currentTime = parseInt(time)
    },
    secToTime
  }
}
</script>

<style lang="scss">
@import "~@styles/variables";
@import "~@styles/tools";

.audio-player {
  &__main {
    display: flex;
    align-items: center;
  }

  &__info {
    display: flex;
    justify-content: space-between;
  }

  &__controls {
    flex: 0 0 auto;
  }

  &__progress {
    flex: 1 1 100%;
    margin-right: .5rem;
    margin-left: 1rem;
  }

  &__play-btn {
    svg {
      width: 1rem;
      height: 1rem;
      top: 0;
      left: 2px;
      fill: white;
      stroke: none;
    }
  }

  &__time {
    text-align: right;
    min-width: 3.5rem;
  }

  &_is {
    &_play {
      .audio-player {
        &__play-btn {
          svg {
            width: 1rem;
            height: 1rem;
            top: 0;
            left: 1px;
            fill: white;
            stroke: white;
          }
        }
      }
    }
  }
}
</style>
