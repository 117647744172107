const defaultState = {
  isShown: false,
  content: '',
  options: {
    endless: false,
    position: null, // left, right
    timeout: 5000,
    color: null
  }
}

export default {
  namespaced: true,

  state: { ...defaultState },

  mutations: {
    SHOW_SNACKBAR (state) {
      state.isShown = true
    },
    HIDE_SNACKBAR (state) {
      state.isShown = false
    },
    SET_CONTENT (state, content) {
      state.content = content
    },
    SET_OPTIONS (state, options) {
      state.options = {
        ...state.options,
        ...options
      }
    },
    CLEAR_SNACKBAR (state) {
      state.content = ''
      state.options = { ...defaultState.options }
    }
  },

  actions: {
    showSnackbar ({ commit, state }, { content, options }) {
      if (state.isShown) {
        commit('CLEAR_SNACKBAR')
      }

      commit('SET_CONTENT', content)

      if (options) {
        commit('SET_OPTIONS', options)
      }

      commit('SHOW_SNACKBAR')
    },
    hideSnackbar ({ commit }) {
      commit('HIDE_SNACKBAR')
      commit('CLEAR_SNACKBAR')
    }
  }
}
