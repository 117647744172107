<template>
  <section class="container">
    <div class="row align-items-center footer-content py-24">
      <template v-if="currentTheme === 'evraz'">
        <div class="col-auto">
          <img
            src="~@assets/images/evraz/logo.svg"
            alt="ЕВРАЗ"
            class="logo__img"
            draggable="false"
          />
        </div>
      </template>

      <template v-else>
        <div class="col-md-12 col-lg">
          {{ $t("site.footer.contactUs") }}:

          <span class="footer-content__contacts">
            <template v-if="phone">
              <a
                :href="`tel:${phone}`"
                class="link-shadow"
              >
                {{ phone | toPhoneNumber }}
              </a>
              &nbsp;<span class="text-border">|</span>&nbsp;
            </template>
            <a
              :href="`mailto:${email}`"
              class="link-underline"
            >
              {{ email }}
            </a>
          </span>
        </div>

        <div class="col-lg-auto footer-content__copyright text-size-14">
          {{ copyright }}
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import toPhoneNumber from '@filters/toPhoneNumber'
import { mapState } from 'vuex'

export default {
  name: 'AppFooter',

  filters: {
    toPhoneNumber
  },

  props: {
    copyright: String,
    email: String,
    phone: String
  },

  computed: {
    ...mapState({
      currentTheme: state => state.theme.current
    })
  }
}
</script>

<style lang="scss" scoped>
@import "~@assets/styles/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.footer-content {
  font-size: .75rem;

  @include media-breakpoint-only(md) {
    text-align: center;
  }

  &__contacts {
    padding-left: 1rem;

    @include media-breakpoint-down(sm) {
      display: block;
      margin-top: 1rem;
      padding-left: 0;
    }
  }

  &__copyright {
    @include media-breakpoint-down(md) {
      margin-top: 1rem;
    }
  }
}

// todo EVRAZ
//.logo {
//  display: flex;
//  text-decoration: none;
//  user-select: none;
//
//  &__img {
//    display: block;
//    width: auto;
//    height: 1rem;
//    padding: 0;
//    margin: 0
//  }
//}
</style>
