<template>
  <div
    :class="classesMain"
    class="v-expansion-panel"
  >
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'VExpansionPanel',

  inject: ['expansionPanels'],

  provide () {
    return {
      expansionPanel: this
    }
  },

  props: {
    disabled: Boolean,
    readonly: Boolean,
    openAtStart: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isOpen: this.expansionPanels.openAtStart,
      content: null,
      header: null
    }
  },

  computed: {
    classesMain () {
      return {
        'v-expansion-panel_is_open': this.isOpen,
        'v-expansion-panel_is_disabled': this.isDisabled
      }
    },

    isDisabled () {
      return this.expansionPanels.disabled || this.disabled
    },
    isReadonly () {
      return this.expansionPanels.readonly || this.readonly
    }
  },

  methods: {
    registerContent (vm) {
      this.content = vm
    },
    unregisterContent () {
      this.content = null
    },
    registerHeader (vm) {
      this.header = vm
      vm.$on('click', this.onClick)
    },
    unregisterHeader () {
      this.header = null
    },
    onClick (e) {
      if (e.detail) this.header && this.header.$el.blur()

      this.$emit('click', e)

      this.isReadonly || this.isDisabled || this.toggle()
    },
    toggle () {
      this.isOpen = !this.isOpen
      this.$nextTick(() => this.$emit('change'))
    }
  }
}
</script>
