<template>
  <div
    :class="mainClasses"
    class="v-expansion-panels"
  >
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'VExpansionPanels',

  props: {
    disabled: Boolean, // Disables the entire expansion-panel
    flat: Boolean,
    readonly: Boolean, // Makes the entire expansion-panel read only
    tile: Boolean, // Removes the border-radius
    openAtStart: {
      type: Boolean,
      default: false
    }
  },

  provide () {
    return {
      expansionPanels: this
    }
  },

  computed: {
    mainClasses () {
      return {
        'v-expansion-panels_type_flat': this.flat,
        'v-expansion-panels_type_tile': this.tile
      }
    }
  }
}
</script>

<style lang="scss">
@import "~@styles/variables";
@import "~@styles/tools";

$expansion-panel-border: 1px solid cl(default);

.v-expansion-panels {
  .v-expansion-panel {
    display: flex;
    flex-direction: column;

    &:first-child {
      .v-expansion-panel-header {
        border-radius: br(null) br(null) 0 0;
      }
    }

    &:last-child {
      .v-expansion-panel-header {
        border-radius: 0 0 br(null) br(null);
        border-bottom: $expansion-panel-border;
      }

      &.v-expansion-panel_is_open {
        .v-expansion-panel-header {
          border-radius: 0;
          border-bottom: 0
        }

        .v-expansion-panel-content {
          border-radius: 0 0 br(null) br(null);
          border-bottom: $expansion-panel-border;
        }
      }
    }
  }

  &_type {
    &_flat {
      .v-expansion-panel-header,
      .v-expansion-panel-content {
        border: none !important;
        border-radius: 0 !important;
        padding: 0;
      }
    }

    &_tile {
      .v-expansion-panel-header,
      .v-expansion-panel-content {
        border-radius: 0 !important;
      }
    }
  }
}
</style>
