import { MODES } from '@constants/components'

/**
 * @typedef {Object} ModeState
 * @property {string[]} current - current modes
 * @property {string[]} all - array of available states
 */

/**
 * @type {ModeState}
 */
const defaultState = {
  current: [],
  all: MODES
}

export default {
  namespaced: true,

  state: { ...defaultState },

  getters: {
    isModeExistByName: (state) => (name) => state.current.includes(name)
  },

  mutations: {
    /**
     * Add another one mode
     * @param {ModeState} state
     * @param {string} mode - new mode
     * @constructor
     */
    ADD_MODE (state, mode) {
      state.current = [
        ...state.current,
        mode
      ]
    },

    /**
     * Clear modes
     * @param {ModeState} state
     * @constructor
     */
    CLEAR_MODES (state) {
      state.current = []
    }
  },

  actions: {
    setMode ({ commit, state }, modeName) {
      if (!state.all.includes(modeName)) {
        return new Error(`Режима работы с именем ${modeName} не существует!`)
      }

      commit('ADD_MODE', modeName)
    },

    clearModes ({ commit }) {
      commit('CLEAR_MODES')
    }
  }
}
