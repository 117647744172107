<template>
  <section
    :class="classes"
    class="v-linear-radio"
  >
    <div class="v-linear-radio__container">
        <v-radio
          v-for="variant in variants"
          :bg-color="bgColor"
          :color="color"
          :key="variant.code"
          :model-value="value"
          :size="size"
          :title="variant.text"
          :value="variant.code"
          @change="setValue"
          class="v-linear-radio__option"
        >
          {{ variant.text }}
        </v-radio>
    </div>

    <div class="v-linear-radio__line"></div>
  </section>
</template>

<script>
import VRadio from '@components/base/VRadio'
import { Colorable, Sizeable } from '@mixins'

export default {
  name: 'VLinearRadio',

  components: { VRadio },

  mixins: [
    Colorable,
    Sizeable
  ],

  props: {
    bgColor: {
      type: String,
      default: '#FFFFFF'
    },
    type360: Boolean,
    value: {
      type: [String, Number],
      required: true
    },
    variants: {
      type: Array,
      required: true
    }
  },

  computed: {
    isActive () {
      return this.variants.findIndex(variant => variant.code === this.value) > -1
    },
    classes () {
      return {
        'v-linear-radio_is_active': this.isActive,
        'v-linear-radio_type_360': this.type360,
        ...this.colorClasses,
        ...this.sizeClasses
      }
    }
  },

  methods: {
    /**
     * Устанавливат новове значение
     * @param {string | number} newValue - выбранное значение
     */
    setValue (newValue) {
      this.$emit('input', newValue)
    }
  }
}
</script>

<style lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";
@import "~@styles/variables";
@import "~@styles/tools";

.v-linear-radio {
  position: relative;
  width: 100%;

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;

    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  &__option {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    z-index: 1;
    padding-right: .25rem;
    padding-left: .25rem;
    min-width: 2rem;
    width: 100%;
    text-align: left;

    @include media-breakpoint-down(sm) {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      padding: 1rem 0;

      &:first-child {
        padding: 0
      }

      &:last-child {
        padding: 0
      }
    }

    @include media-breakpoint-up(md) {
      justify-content: center;
      text-align: center;
      width: auto;

      &:nth-child(-n+1) {
        justify-content: flex-start;
        padding-left: 0;
        text-align: left;
      }

      &:nth-last-child(-n+1) {
        justify-content: flex-end;
        padding-right: 0;
        text-align: right;
      }
    }

    .v-radio__container {
      order: 2;

      @include media-breakpoint-down(sm) {
        order: 1;
        margin-right: .5rem;
      }
    }

    .v-radio__slot {
      display: block;
      order: 1;
      overflow: hidden;
      width: 100%;
      margin: 0 .2rem .3rem;
      font-size: .75rem;
      //white-space: nowrap;
      //text-overflow: ellipsis;

      @include media-breakpoint-down(sm) {
        order: 2;
        margin: 0;
      }
    }
  }

  &__line {
    z-index: 0;
    position: absolute;
    top: auto;
    right: 1rem;
    bottom: 0.7rem;
    left: 1rem;
    height: 1px;
    background-color: cl(primary);

    @include media-breakpoint-down(sm) {
      top: 1rem;
      right: auto;
      bottom: 1rem;
      left: 0.67rem;
      height: auto;
      width: 1px;
    }
  }

  &_size {
    @each $el-size in map-keys($spacers) {
      &_#{$el-size} {
        > .v-linear-radio__line {
          bottom: map-get($spacers, $el-size) / 2 + 0.2;

          @include media-breakpoint-down(sm) {
            left: map-get($spacers, $el-size) / 2 + 0.1;
          }
        }
      }
    }
  }

  &_color {
    @each $key, $value in $theme-colors {
      &_#{$key} {
        .v-linear-radio__line {
          background-color: cl($key);
        }
      }
    }
  }

  &_type {
    &_360 {
      @include media-breakpoint-up(xl) {
        .v-radio__slot {
          opacity: 0;
          transition: opacity .3s ease;
        }

        .v-linear-radio__option {
          .v-radio__input:checked ~ ,
          &:hover {
            .v-radio__slot {
              opacity: 1;
            }
          }
        }
      }

      > .v-linear-radio__line {
        background-color: cl(border);
        transition: background-color .3s ease;
      }

      &.v-linear-radio_is_active {
        > .v-linear-radio__line {
          background-color: cl(primary);
        }
      }
    }
  }
}
</style>
