const defaultState = {
  isShown: false,
  component: {
    name: 'MBase',
    props: {},
    emits: {}
  },
  options: {
    clickOutsideToClose: true,
    closeOnEsc: true
  }
}

export default {
  namespaced: true,

  state: { ...defaultState },

  mutations: {
    SHOW_MODAL (state) {
      state.isShown = true
    },
    HIDE_MODAL (state) {
      state.isShown = false
    },
    CLEAR_MODAL (state) {
      state.component = { ...defaultState.component }
      state.options = { ...defaultState.options }
    },
    SET_COMPONENT (state, component) {
      state.component = {
        ...state.component,
        ...component
      }
    },
    SET_OPTIONS (state, options) {
      state.options = {
        ...state.options,
        ...options
      }
    }
  },

  actions: {
    showModal ({ commit }, { component, options } = {}) {
      if (component) {
        commit('SET_COMPONENT', component)
      }

      if (options) {
        commit('SET_OPTIONS', options)
      }

      commit('SHOW_MODAL')
    },
    hideModal ({ commit }) {
      commit('HIDE_MODAL')
      commit('CLEAR_MODAL')
    }
  }
}
