<template>
  <div
    v-ripple="computedRipple"
    :class="mainClasses"
    class="v-sheet"
    @click="onClick"
  >
    <slot/>
  </div>
</template>

<script>
import { Ripple } from '@directives'
import { Colorable, Elevatable, Roundable } from '@/mixins'

export default {
  name: 'VSheet',

  directives: {
    Ripple
  },

  mixins: [
    Colorable,
    Elevatable,
    Roundable
  ],

  /**
   * @property {Boolean} cover - подсветка на ::before вместо background
   * @property {Boolean} disabled - неактивный, подсветка серым
   * @property {Boolean} hovered - подсветка при наведении
   * @property {Boolean} outlined - без box-shadow, с border
   * @property {Boolean} readonly - неактивный
   * @property {Boolean} ripple - настройка ripple
   * @property {Boolean} value - активно, или нет. Для работы с v-model
   */
  props: {
    cover: Boolean,
    disabled: Boolean,
    hovered: Boolean,
    outlined: Boolean,
    pending: Boolean,
    readonly: Boolean,
    ripple: {
      type: [Boolean, Object],
      default: () => ({ class: 'text-color-primary' })
    },
    value: {
      type: [Boolean, String, Number],
      default: null
    }
  },

  computed: {
    computedRipple () {
      return this.isClickable
        ? this.ripple
        : false
    },
    isClickable () {
      return this.value !== null && !this.disabled && !this.readonly
    },
    mainClasses () {
      return {
        'v-sheet_is_active': this.value,
        'v-sheet_is_disabled': this.disabled,
        'v-sheet_is_pending': this.pending,
        'v-sheet_type_clickable': this.isClickable,
        'v-sheet_type_cover': this.cover,
        'v-sheet_type_hovered': this.hovered || this.isClickable,
        'v-sheet_type_outlined': this.outlined,
        ...this.colorClasses,
        ...this.elevationClasses,
        ...this.roundedClasses
      }
    }
  },

  methods: {
    onClick (e) {
      this.onInput()
      !this.disabled && this.$emit('click', e)
    },

    onInput (e) {
      !this.disabled && this.$emit('input', e)
    }
  }
}
</script>

<style lang="scss">
@import "~@styles/variables";
@import "~@styles/tools";

.v-sheet {
  // Это тут обязательно, иначе ripples выходит за рамки v-sheet
  position: relative;
  width: 100%;
  background-color: #fff;
  border: var(--v-sheet-border);

  > *:not(.v-button) {
    &:first-child {
      border-top-right-radius: inherit;
      border-top-left-radius: inherit;
    }

    &:last-child {
      border-bottom-right-radius: inherit;
      border-bottom-left-radius: inherit;
    }
  }

  &_is {
    &_disabled {
      opacity: .5;

      &::before {
        z-index: 100;
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: inherit;
        background-color: transparent;
        opacity: 0;
        cursor: default;
        user-select: none;
      }
    }

    &_pending {
      position: relative;

      &::before {
        content: "";
        z-index: 100;
        user-select: none;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(white, .5);
        border-radius: inherit;
      }
    }
  }

  &_type {
    // когда background не подходит, например v-sheet с картинками
    &_cover {
      position: relative;
      background-color: transparent;

      transition: border-color .3s ease;

      &::before {
        content: "";
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: inherit;
        background-color: var(--v-sheet-color-primary);
        opacity: 0;
        transition: opacity .3s ease;
        user-select: none;
      }
    }

    &_clickable {
      user-select: none;
      cursor: pointer;

      &.v-sheet_is_active {
        &:not(.v-sheet_type_cover) {
          background-color: rgba(var(--v-sheet-color-rgb-primary), .1);
        }

        &.v-sheet_type_cover {
          &::before {
            opacity: .15;
          }
        }
      }
    }

    &_outlined {
      box-shadow: none;
      border: 1px solid cl(border);

      &.v-sheet {
        &_is {
          &_active {
            border-color: rgba(var(--v-sheet-color-rgb-primary), .4);

            .v-divider {
              border-color: rgba(var(--v-sheet-color-rgb-primary), .4);
            }
          }

          &_disabled {
            border-color: cl(border) !important;

            .v-divider {
              border-color: cl(border) !important;
            }
          }
        }

        &_type {
          &_hovered {
            &:hover {
              border-color: rgba(var(--v-sheet-color-rgb-primary), .4);

              .v-divider {
                border-color: rgba(var(--v-sheet-color-rgb-primary), .4);
              }
            }
          }
        }
      }
    }
  }

  &_color {
    @each $color in map-keys($theme-colors) {
      &_#{$color} {
        &.v-sheet {
          &_type {
            &_cover::before {
              background-color: cl($color);
            }

            &_clickable {
              &.v-sheet_is_active {
                &:not(.v-sheet_type_cover, .v-sheet_is_disabled) {
                  background-color: rgba(cl(rgb-#{$color}), .1);
                }
              }
            }

            &_outlined {
              border-color: rgba(cl(rgb-#{$color}), 1)
            }
          }
        }
      }
    }
  }
}
</style>
