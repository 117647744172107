<template>
  <input
    class="v-input"
    v-model="model"
    v-bind="attributes"
    v-on="listeners"
    @focus="onFocus"
    @blur="onBlur"
  >
</template>

<script>
import { getRandomUuid } from '@utils/helpers'
import VFieldMixin from '../VFieldMixin'

export default {
  name: 'VInput',
  mixins: [VFieldMixin],
  inject: ['VField'],
  props: {
    id: {
      type: String,
      default: () => 'v-input-' + getRandomUuid()
    },
    type: {
      type: String,
      default: 'text'
    },
    autogrow: Boolean
  },

  computed: {
    listeners () {
      const l = { ...this.$listeners }
      delete l.input
      return l
    }
  },

  created () {
    this.setAutogrow()
  },

  methods: {
    setAutogrow () {
      this.VField.autogrow = this.autogrow
    }
  }
}
</script>
