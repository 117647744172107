import { COLORS } from '@constants/components'
import { convertCamelToKebab } from '@utils/helpers'

export default {
  props: {
    color: {
      type: String,
      validator: color => COLORS.includes(color)
    }
  },

  computed: {
    colorClasses () {
      return {
        [`${convertCamelToKebab(this.$options.name)}_color_${this.color}`]: !!this.color
      }
    }
  }
}
