import language from './language'
import loading from './loading'
import modal from './modal'
import mode from './mode'
import snackbar from './snackbar'
import theme from './theme'

export default {
  language,
  loading,
  modal,
  mode,
  snackbar,
  theme
}
