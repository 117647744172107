<template>
  <div
    :class="classes"
    class="v-card"
    @click="onClick"
  >
    <slot/>
  </div>
</template>

<script>
import { Ripple } from '@directives'
import { Colorable } from '@/mixins'

export default {
  name: 'VCard',

  directives: {
    Ripple
  },

  mixins: [Colorable],

  /**
   * @property {Boolean} disabled - отключено
   * @property {Boolean} outlined - без box-shadow, с border
   * @property {Boolean} tile - border-radius = 4px
   * @property {Boolean} value - активно, или нет. Для работы с v-model
   */
  props: {
    disabled: Boolean,
    outlined: Boolean,
    ripple: {
      default: false,
      type: [Boolean, Object]
    },
    tile: Boolean,
    value: Boolean
  },

  computed: {
    classes () {
      return {
        'v-card_is_active': this.value,
        'v-card_is_disabled': this.disabled,
        'v-card_type_outlined': this.outlined,
        'v-card_type_tile': this.tile,
        ...this.colorClasses
      }
    }
  },

  methods: {
    onClick () {
      this.onInput()
      this.$emit('click', this.value)
    },

    onInput () {
      this.$emit('input', !this.value)
    }
  }
}
</script>

<style lang="scss">
@import "~@styles/variables";
@import "~@styles/tools";

.v-card {
  width: 100%;
  background-color: #fff;
  @include elevation(2);
  @include radius(lg);

  > *:not(.v-button) {
    &:first-child {
      border-top-right-radius: inherit;
      border-top-left-radius: inherit;
    }

    &:last-child {
      border-bottom-right-radius: inherit;
      border-bottom-left-radius: inherit;
    }
  }

  &_is {
    &_pending {
      position: relative;

      &::before {
        content: "";
        z-index: 100;
        user-select: none;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(white, .5);
        border-radius: inherit;
      }
    }
  }

  &_type {
    &_tile {
      border-radius: br(null);
    }

    &_outlined {
      box-shadow: none;
      border: 1px solid cl(border);
    }
  }

  &_color {
    @each $color in map-keys($theme-colors) {
      &_#{$color} {
        background-color: rgba(cl(rgb-#{$color}), .3);

        //&:not(.v-card_type_outlined) {
        //  box-shadow: el-shadow($color);
        //}

        &.v-card_type_outlined {
          border-color: cl($color)
        }
      }
    }
  }
}
</style>
