import { getType } from '@utils/helpers'

/**
 * Convert object to form data
 * @param {Object} data - data object
 * @returns {FormData} - formData
 */
export default function convertToFormData (data) {
  const bodyFormData = new FormData();

  (
    /**
     * Unfolds data
     * @param {string | null} key
     * @param {*} value
     */
    function deepAppend (key, value) {
      if (getType(value) === 'array') {
        if (value.length) {
          value.forEach(arrValue => deepAppend(key, arrValue))
        } else {
          bodyFormData.append(key, value)
        }

        return
      }

      if (getType(value) === 'object') {
        const ObjectKeys = Object.keys(value)

        if (ObjectKeys.length) {
          Object.keys(value).forEach(function (key) {
            deepAppend(key, this[key])
          }, value)
        } else {
          bodyFormData.append(key, value)
        }

        return
      }

      // удаление внутренних состояний
      if (key !== null) {
        bodyFormData.append(key, value)
      }
    })(null, data)

  return bodyFormData
}
