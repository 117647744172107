<template>
  <v-sheet
      :color="currentColor"
      :disabled="disabled"
      :value="isActive"
      hovered
      outlined
      rounded
      class="mb-8"
  >
    <v-radio
        :disabled="disabled"
        :model-value="modelValue"
        :value="value"
        @change="onChange($event)"
        class="p-8"
    >
      <slot/>
    </v-radio>
  </v-sheet>
</template>

<script>
import { VRadio, VSheet } from '@components/base'

export default {
  name: 'CardButtonRadio',

  components: { VRadio, VSheet },

  model: {
    prop: 'modelValue',
    event: 'change'
  },

  props: {
    disabled: Boolean,
    error: Boolean,
    modelValue: [String, Number],
    value: [String, Number]
  },

  computed: {
    isActive () {
      return this.modelValue === this.value
    },
    currentColor () {
      if (this.error) return 'danger'
      return null
    }
  },

  methods: {
    onChange (values) {
      this.$emit('change', values)
    }
  }
}
</script>
