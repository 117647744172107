/**
 * Convert camelCase to kebab-case
 * @param {string} camel - camelCase string
 * @return {string} - kebab-case string
 */
export default function convertCamelToKebab (camel) {
  return camel.split('').map((letter, idx) => {
    return letter.toUpperCase() === letter && isNaN(letter)
      ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}`
      : letter
  }).join('')
}
