<template>
  <textarea
    class="v-textarea"
    :style="textareaStyles"
    v-model="model"
    v-bind="attributes"
    v-on="listeners"
    @focus="onFocus"
    @blur="onBlur">
  </textarea>
</template>

<script>
import { getRandomUuid } from '@utils/helpers'
import VFieldMixin from '../VFieldMixin'

function calculateContentHeight (el, lineHeight) {
  const origHeight = el.style.height
  const height = el.offsetHeight
  const scrollHeight = el.scrollHeight

  el.style.overflow = 'hidden'

  if (height >= scrollHeight) {
    el.style.height = (height + lineHeight) + 'px'

    if (scrollHeight < el.scrollHeight) {
      el.style.height = origHeight

      return height
    }
  }

  return scrollHeight + 3
}

export default {
  name: 'VTextarea',

  mixins: [VFieldMixin],
  inject: ['VField'],

  props: {
    id: {
      type: String,
      default: () => 'v-textarea-' + getRandomUuid()
    },
    rows: {
      type: Number,
      default: 1
    },
    autogrow: Boolean
  },

  computed: {
    listeners () {
      return {
        ...this.$listeners,
        input: this.onInput
      }
    },
    textareaStyles () {
      return {
        height: this.textareaHeight
      }
    }
  },

  methods: {
    getTextAreaLineSize () {
      const style = window.getComputedStyle(this.$el)

      return parseInt(style.lineHeight, 10)
    },

    setTextAreaSize (height) {
      let newHeight = height

      if (!height) {
        const size = this.getTextAreaLineSize()

        newHeight = calculateContentHeight(this.$el, size)
      }

      this.textareaHeight = newHeight + 'px'
    },

    applyStyles () {
      if (this.autogrow) {
        const baseHeight = 32

        this.setTextAreaSize(baseHeight * this.rows)
        this.$nextTick().then(() => {
          this.setTextAreaSize()
          window.setTimeout(() => {
            this.$el.style.overflow = 'auto'
          }, 10)
        })
      }
    },

    setTextarea () {
      this.VField.textarea = true
    },

    setAutogrow () {
      this.VField.autogrow = this.autogrow
    },

    onInput () {
      this.setFieldValue()
    }
  },

  watch: {
    localValue () {
      this.applyStyles()
    }
  },

  created () {
    this.setTextarea()
    this.setAutogrow()
  },

  mounted () {
    this.$nextTick(() => {
      this.applyStyles()
    })
  },

  beforeDestroy () {
    this.setTextarea(false)
  }
}
</script>
