<template>
  <div
    :class="classes"
    @blur="onBlur"
    class="v-field"
  >
    <slot/>

    <span
      v-if="isAutogrowInput"
      class="v-field__shadow"
    >
      {{ VField.value || VField.placeholderText }}
    </span>

    <span
      v-if="hasCounter"
      class="v-field__count"
    >
      {{ valueLength }} / {{ VField.maxlength || VField.counter }}
    </span>
  </div>
</template>

<script>
import { Colorable, Roundable, Sizeable } from '@mixins'

export default {
  name: 'VField',

  mixins: [
    Sizeable,
    Roundable,
    Colorable
  ],

  props: {
    error: Boolean,
    inline: Boolean,
    counter: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    VField: {
      autogrow: false,
      clear: false,
      counter: null,
      disabled: false,
      file: false,
      focused: false,
      hasInvalidValue: false,
      highlighted: false,
      label: false,
      maxlength: null,
      placeholder: false,
      placeholderText: null,
      required: false,
      textarea: false,
      value: null
    }
  }),

  provide () {
    return {
      VField: this.VField
    }
  },

  computed: {
    isAutogrowInput () {
      return this.VField.autogrow && !this.VField.textarea
    },
    stringValue () {
      return (this.VField.value || this.VField.value === 0) && this.VField.value.toString()
    },
    hasCounter () {
      return this.counter && (this.VField.maxlength || this.VField.counter)
    },
    hasValue () {
      return (this.stringValue && this.stringValue.length > 0) || this.VField.hasInvalidValue
    },
    valueLength () {
      if (this.stringValue) {
        return this.stringValue.length
      }

      return 0
    },
    classes () {
      return {
        'v-field_has_file': this.VField.file,
        'v-field_has_label': this.VField.label,
        'v-field_has_placeholder': this.VField.placeholder,
        'v-field_has_select': this.VField.select,
        'v-field_has_textarea': this.VField.textarea,
        'v-field_has_value': this.hasValue,
        'v-field_is_autogrow': this.VField.autogrow,
        'v-field_is_disabled': this.VField.disabled,
        'v-field_is_error': this.error,
        'v-field_is_focused': this.VField.focused,
        'v-field_is_required': this.VField.required,
        'v-field_is_inline': this.inline,
        ...this.colorClasses,
        ...this.sizeClasses,
        ...this.roundedClasses
      }
    }
  },

  methods: {
    clearInput () {
      this.VField.clear = true
      this.$emit('clear')
      this.$nextTick().then(() => {
        this.VField.clear = false
      })
    },
    onBlur () {
      this.VField.highlighted = false
    }
  }
}
</script>

<style lang="scss">
@import "~@styles/variables";
@import "~@styles/tools";

$input-field-transition: border-color .25s map-get($transition, "ease-in-out"), background-color .25s map-get($transition, "ease-in-out");
$input-label-transition: transform .1s map-get($transition, "fast-in-fast-out"), color .1s map-get($transition, "fast-in-fast-out"), font-size .1s map-get($transition, "fast-in-fast-out");

.v-field {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: .5rem;
  margin-bottom: .75rem;

  &__help,
  &__error {
    display: inline-block;
    background: transparent;
    color: gray;
    font-size: .7rem;
    line-height: 1.2;
    font-style: normal;
    margin-top: .25rem;
    padding: 0;
  }

  &__error {
    display: none;
  }

  .v-input,
  .v-textarea,
  .v-field__shadow {
    width: 100%;
    padding: .5rem .75rem;
    margin: 0;
    font: 400 1rem $font-primary;
    line-height: 1.2;
    color: cl(text-primary);
    border: 1px solid transparent;
    background-color: rgba(cl(rgb-primary), .08);
    border-radius: br(null);
    transition: $input-field-transition;

    &:focus {
      outline: none;
    }

    &:disabled,
    &[disabled] {
      opacity: .5;
      cursor: default;
    }

    &::placeholder {
      font-family: var(--font-primary);
      font-size: 1rem;
      font-weight: 400;
      color: cl(text-secondary);
      user-select: none;
    }
  }

  .v-textarea {
    resize: vertical;
    min-height: calc(2.2rem + 2px);
  }

  label {
    z-index: 2;
    position: absolute;
    overflow: hidden;
    display: block;
    top: .5rem;
    left: .75rem;
    width: auto;
    max-width: calc(100% - 1.5rem);
    font-size: 1rem;
    line-height: 1.2;
    white-space: nowrap;
    color: cl(text-secondary);
    transform: translate(0, 0);
    transition: $input-label-transition;
    transform-origin: 0 0;
    text-align: left;
    cursor: text;
    text-overflow: ellipsis;
    pointer-events: none;
    user-select: none;
  }

  &_size {
    &_xs,
    &_12 {
      .v-input,
      .v-textarea,
      .v-field__shadow {
        font-size: .75rem;

        &::placeholder {
          font-size: .75rem;
        }
      }

      label {
        font-size: .75rem;
      }

      .v-textarea {
        min-height: calc(1.5rem + 2px);
      }
    }
  }

  &_has {
    // input has some value
    &_value {
      label {
        max-width: 100%;
        color: cl(text-primary);
        font-size: .75rem;
        transform: translate(-.75rem, -1.6rem);
      }

      .v-input,
      .v-textarea,
      .v-field__shadow {
        border-color: cl(primary);
        background-color: transparent;
      }
    }

    &_label {
      .v-input,
      .v-textarea {
        &::placeholder {
          color: transparent;
        }
      }
    }
  }

  &_is {
    &_autogrow {
      &:not(.v-field_has_textarea) {
        display: inline-flex;
        width: auto;
        margin-top: .25rem;
        margin-bottom: .25rem;
        vertical-align: middle;
      }

      .v-field__shadow {
        display: inline-block;
        min-height: calc(2.2rem + 2px);
        width: auto;
        padding-right: 1rem;
        min-width: 6rem;
        opacity: 0;
      }

      .v-input {
        z-index: 1;
        position: absolute;
        left: 0;

        &::-ms-clear {
          display: none;
        }
      }
    }

    // input get focus
    &_focused {
      label {
        max-width: 100%;
        color: cl(text-primary);
        font-size: .75rem;
        transform: translate(-.75rem, -1.6rem);
      }

      .v-input,
      .v-textarea {
        border-color: rgba(cl(rgb-primary), .5);
        background-color: transparent;

        &::placeholder {
          color: cl(text-secondary);
        }
      }
    }

    &_required {
      label {
        display: inline-block;
        padding-right: 8px;

        &:before {
          content: "*";
          position: absolute;
          top: 0;
          right: 0;
          font-size: 12px;
          color: cl(danger);
        }
      }
    }

    // input error
    &_error {
      label,
      .v-input,
      .v-textarea,
      .v-field__error {
        color: cl(danger);
      }

      .v-input,
      .v-textarea {
        border-color: cl(danger);

        &::placeholder {
          color: cl(danger);
        }
      }

      .v-field__help {
        display: none;
      }

      .v-field__error {
        display: inline-block;
      }

      &.v-field_focused,
      &.v-field_has-value {
        .v-input,
        .v-textarea {
          border-color: cl(danger);
        }
      }
    }
  }

}
</style>
