<template>
  <label
    :class="classes"
    class="v-radio"
  >
    <input
      :id="id"
      :name="name"
      :value="value"
      :checked="shouldBeChecked"
      :disabled="disabled"
      @change="onChange"
      @focus="onFocus"
      @blur="onBlur"
      type="radio"
      class="v-radio__input"
    >

    <span class="v-radio__container">
      <svg
        :style="bulletStyles"
        class="v-radio__bullet"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 1024 1024"
      >
        <circle cx="512" cy="512" r="400"/>
        <circle class="v-radio__indicator" cx="512" cy="512" r="190"/>
      </svg>
    </span>

    <span
      v-if="this.$slots.default"
      class="v-radio__slot"
    >
      <slot></slot>
    </span>
  </label>
</template>

<script>
import { Colorable, Sizeable } from '@mixins'

export default {
  name: 'VRadio',

  mixins: [
    Colorable,
    Sizeable
  ],

  model: {
    prop: 'modelValue',
    event: 'change'
  },

  props: {
    bgColor: String,
    disabled: Boolean,
    error: Boolean, // todo #depricated - использовать color вместо этого
    fullHeight: Boolean,
    id: String,
    modelValue: {
      default: ''
    },
    name: String,
    success: Boolean, // todo #depricated - использовать color вместо этого
    value: {
      default: null
    }
  },

  data () {
    return {
      isFocused: false
    }
  },

  computed: {
    classes () {
      return {
        'v-radio_is_full-height': this.fullHeight,
        'v-radio_is_error': this.error, // todo #depricated - использовать color вместо этого
        'v-radio_is_success': this.success, // todo #depricated - использовать color вместо этого
        'v-radio_is_disabled': this.disabled,
        ...this.colorClasses,
        ...this.sizeClasses
      }
    },

    bulletStyles () {
      return {
        backgroundColor: this.bgColor
      }
    },

    shouldBeChecked () {
      return this.modelValue === this.value
    }
  },

  methods: {
    onChange () {
      this.$emit('change', this.value, this.shouldBeChecked)
    },
    onFocus (e) {
      this.isFocused = true
      this.$emit('focus', e)
    },
    onBlur (e) {
      this.isFocused = false
      this.$emit('blur', e)
    }
  }
}
</script>

<style lang="scss">
@import "~@styles/variables";
@import "~@styles/tools";

.v-radio {
  position: relative;
  display: inline-flex;
  width: 100%; // обеспечивает растягивание на всю длину
  min-width: 1.4rem; // IE11
  min-height: 1.4rem; // IE11
  outline: none;
  cursor: pointer;

  &__input {
    position: absolute;
    opacity: 0;
    width: 1rem;
    height: 1rem;
    margin: .2rem;
    padding: 0;
    border: none;
    user-select: none;

    &:checked + .v-radio__container {
      .v-radio__bullet {
        stroke: cl(primary);
      }

      .v-radio__indicator {
        transform: scale(1);
        opacity: 1;
      }
    }

    &:disabled + .v-radio__bullet {
      stroke: cl(ui-3);

      .v-radio__indicator {
        fill: cl(ui-3)
      }
    }
  }

  &__container {
    position: relative;
    display: inline-flex;
    flex: 0 0 auto;
    width: 1rem;
    height: 1rem;
    margin: .2rem;

    &:hover {
      .v-radio__bullet {
        stroke: cl(primary);
      }

      &::before {
        transform: scale(0.9); // Не менять на 1. Вызывает баг в виде полосы прокрутки
        opacity: .1;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: -.5rem;
      right: -.5rem;
      bottom: -.5rem;
      left: -.5rem;
      border-radius: 100%;
      background-color: cl(primary);
      opacity: 0;
      transform: scale(0.4);
      transition: opacity .3s map-get($transition, "ease-in-out"), transform .2s map-get($transition, "ease-in-out");
    }
  }

  &__bullet {
    width: 1rem;
    height: 1rem;
    user-select: none;
    border-radius: 100%;
    background-color: transparent;
    fill: none;
    stroke: cl(secondary);
    stroke-width: 5%;
  }

  &__indicator {
    fill: cl(primary);
    transform-origin: center center;
    transform: scale(0);
    transition: opacity .1s map-get($transition, "ease-in-out"), transform .1s map-get($transition, "ease-in-out");
    opacity: 0; // IE11
  }

  &__slot {
    display: inline-flex;
    margin-left: .5rem;
    user-select: none;
  }

  &_size {
    @each $key, $value in $spacers {
      &_#{$key} {
        $radio-size: $value;
        $radio-size-width: $radio-size + 0.4rem;

        min-width: $radio-size-width;
        min-height: $radio-size-width;

        .v-radio__container {
          width: $radio-size;
          height: $radio-size;
        }

        .v-radio__input {
          width: $radio-size;
          height: $radio-size;
        }

        .v-radio__bullet {
          width: $radio-size;
          height: $radio-size;
        }
      }
    }
  }

  &_color {
    @each $key, $value in $theme-colors {
      &_#{$key} {
        .v-radio__input:checked + .v-radio__container {
          .v-radio__bullet {
            stroke: cl($key);
          }
        }

        .v-radio__container {
          &::before {
            background-color: cl($key);
          }

          &:hover {
            .v-radio__bullet {
              stroke: cl($key);
            }
          }
        }

        .v-radio__bullet {
          stroke: cl($key);
        }

        .v-radio__indicator {
          fill: cl($key)
        }
      }
    }
  }

  &_is {
    &_full-height {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    // todo #depricated - пользоваться свойством color
    &_success {
      .v-radio__indicator {
        fill: cl(success) !important;
      }

      .v-radio__bullet {
        stroke: cl(success) !important;
      }

      .v-radio__container {
        &::before {
          background-color: cl(success);
        }
      }
    }

    // todo #depricated - пользоваться свойством color
    &_error {
      .v-radio__indicator {
        fill: cl(danger) !important;
      }

      .v-radio__bullet {
        stroke: cl(danger) !important;
      }

      .v-radio__container {
        &::before {
          background-color: cl(danger);
        }
      }
    }

    &_disabled {
      cursor: default;

      .v-radio__indicator {
        fill: cl(text-secondary) !important;
      }

      .v-radio__bullet {
        stroke: cl(text-secondary) !important;
      }

      .v-radio__container {
        &::before {
          background-color: cl(text-secondary);
        }

        &:hover {
          &::before {
            opacity: 0;
            transform: scale(0.4);
          }
        }
      }
    }
  }
}
</style>
