/**
 * Convert hex color to rgb
 * @param {string} hex - hex (#000000)
 * @returns {number[]} - rgb ([r, g, b])
 */
export default hex => {
  if (typeof hex !== 'string') {
    console.error('HEX must be string type. (convertHexToRgb)')
    return []
  }

  if (hex.charAt(0) !== '#') {
    console.error('HEX must start with # symbol. (convertHexToRgb)')
    return []
  }

  return hex
    .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
    .substring(1)
    .match(/.{2}/g)
    .map(x => parseInt(x, 16)) || []
}
