import { loadLanguageAsync } from '@/i18n'

const defaultState = {
  current: null,
  supported: []
}

export default {
  namespaced: true,

  state: { ...defaultState },

  getters: {
    supportedHash (state) {
      return state.supported.reduce((result, { value, label }) => {
        return {
          ...result,
          [value]: label
        }
      }, {})
    },
    supportedShow (state, getters) {
      return Object.keys(getters.supportedHash).length > 1
    }
  },

  mutations: {
    SET_CURRENT (state, language) {
      state.current = language
    },
    SET_SUPPORTED (state, supported) {
      state.supported = [
        ...state.supported,
        ...supported
      ]
    }
  },

  actions: {
    setCurrent ({ commit }, { language }) {
      commit('SET_CURRENT', language)
      return loadLanguageAsync(language)
    },
    setSupported ({ commit }, supported) {
      commit('SET_SUPPORTED', supported)
    }
  }
}
