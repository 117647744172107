/**
 * Переписывает конструкцию вида +70001112233
 * в +7 (000) 111–22–33
 * @param {string} phone - телефон вида +70001112233
 * @returns {string} - телефон вида +7 (000) 111–22–33
 *
 * @example
 * <p>{{ +70001112233 | toPhoneNumber}}</p>
 * отрисует так
 * <p>+7 (000) 111–22–33</p>
 */

export default function (phone) {
  const pattern = /(\+7|8)[\s(]?(\d{3})[\s)]?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/g
  return phone.replace(pattern, '+7 ($2) $3–$4–$5')
}
