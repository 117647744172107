/**
 * Get random integer
 * !excluding maximum and minimum
 * @param {number} min - minimum value
 * @param {number} max - maximum value
 * @returns {number} - random value according to range
 */
export default function getRandomInt (min, max) {
  return Math.floor(min + Math.random() * (max + 1 - min))
}
