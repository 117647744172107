import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ruMessages from '@/languages/ru-RU.json'

Vue.use(VueI18n)

const _loadedLanguages = ['ru-RU']

export const DEFAULT_LOCALE = 'ru-RU'

export const i18n = new VueI18n({
  locale: DEFAULT_LOCALE,
  fallbackLocale: DEFAULT_LOCALE,
  messages: {
    'ru-RU': ruMessages
  },
  // Key - language to use the rule for, `'ru'`, in this case
  // Value - function to choose right plural form
  pluralizationRules: {
    /**
     * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
     * @param choicesLength {number} an overall amount of available choices
     * @returns a final choice index to select plural word by
     */
    'ru-RU': function (choice, choicesLength) {
      // this === VueI18n instance, so the locale property also exists here

      if (choice === 0) {
        return 0
      }

      const teen = choice > 10 && choice < 20
      const endsWithOne = choice % 10 === 1

      if (choicesLength < 4) {
        return (!teen && endsWithOne) ? 1 : 2
      }
      if (!teen && endsWithOne) {
        return 1
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2
      }

      return (choicesLength < 4)
        ? 2
        : 3
    }
  }
})

/**
 * Set language
 * @param {string} lang - new language name
 */
export const setI18nLanguage = lang => {
  i18n.locale = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

/**
 * Set language async
 * @param {string} lang - new language name
 */
export const loadLanguageAsync = lang => {
  // Если локализация та же
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // Если локализация уже была загружена
  if (_loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // Если локализация ещё не была загружена
  return import(`@/languages/${lang}.json`).then(messages => {
    i18n.setLocaleMessage(lang, messages.default)
    _loadedLanguages.push(lang)
    return setI18nLanguage(lang)
  })
}
