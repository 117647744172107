import Guide from '@views/Guide'

const { VUE_APP_GUIDE_PATH } = process.env

export default [{
  path: `${VUE_APP_GUIDE_PATH}`,
  component: Guide,
  redirect: `${VUE_APP_GUIDE_PATH}/ui`,
  children: [
    {
      path: 'ui',
      name: 'UI',
      component: () => import('@views/Guide/Screen/SUi.vue'),
      meta: { title: 'UI' }
    },
    {
      path: 'evraz-lk-mentor',
      name: 'EvrazLKMentor',
      component: () => import('@views/Guide/Screen/SEvrazLK/SEvrazLKMentor.vue'),
      meta: { title: 'ЕВРАЗ Личный кабинет' }
    },
    {
      path: 'evraz-lk',
      name: 'EvrazLK',
      component: () => import('@views/Guide/Screen/SEvrazLK/SEvrazLK.vue'),
      meta: { title: 'ЕВРАЗ Личный кабинет' }
    },
    {
      path: 'wa-tasks',
      name: 'WaTasks',
      component: () => import('@views/Guide/Screen/SWaTasks/SWaTasks.vue'),
      meta: { title: 'W@ Список тестов' }
    },
    {
      path: 'wa-test-list',
      name: 'WaTestList',
      component: () => import('@views/Guide/Screen/SWaTestList.vue'),
      meta: { title: 'W@ Список тестов' }
    },
    {
      path: 'nlmk-cok-negative',
      name: 'NlmkCOKNegative',
      component: () => import('@views/Guide/Screen/SNlmkCOK/SNlmkCOKNegative'),
      meta: { title: 'НЛМК ЦОК со штрафами' }
    },
    {
      path: 'nlmk-cok',
      name: 'NlmkCOK',
      component: () => import('@views/Guide/Screen/SNlmkCOK/SNlmkCOK'),
      meta: { title: 'НЛМК ЦОК' }
    },
    {
      path: 'nlmk-competency-assessment',
      name: 'NlmkCompetencyAssessment',
      component: () => import('@views/Guide/Screen/SNlmkCompetencyAssessment/SNlmkCompetencyAssessment.vue'),
      meta: { title: 'НЛМК Оценка по компетенциям' }
    },
    {
      path: 'nlmk-filling-in-the-pass',
      name: 'NlmkFillingInThePass',
      component: () => import('@views/Guide/Screen/SNlmkFillingInThePass.vue'),
      meta: { title: 'НЛМК Заполнение пропуска' }
    },
    {
      path: 'nlmk-graduated-scale',
      name: 'NlmkGraduatedScale',
      component: () => import('@views/Guide/Screen/SNlmkGraduatedScale.vue'),
      meta: { title: 'НЛМК Градуированная шкала' }
    },
    {
      path: 'tabs',
      name: 'Tabs',
      component: () => import('@views/Guide/Screen/STabs.vue'),
      meta: { title: 'Tabs' }
    },
    {
      path: 'agreement-2',
      name: 'Agreement2',
      component: () => import('@views/Guide/Screen/SAgreement2.vue'),
      meta: { title: 'Правила2' }
    },
    {
      path: '360-stars-in-table',
      name: '360StarsInTable',
      component: () => import('@views/Guide/Screen/S360StarsInTable.vue'),
      meta: { title: '360 Звёздочки в таблице' }
    },
    {
      path: '360-stars',
      name: '360Stars',
      component: () => import('@views/Guide/Screen/S360Stars.vue'),
      meta: { title: '360 Звёздочки' }
    },
    {
      path: '360-choice-of-approval',
      name: '360ChoiceOfApproval',
      component: () => import('@views/Guide/Screen/S360ChoiceOfApproval.vue'),
      meta: { title: '360 Выбор утверждения' }
    },
    {
      path: '360-textarea-table',
      name: '360TextareaTable',
      component: () => import('@views/Guide/Screen/S360TextareaTable.vue'),
      meta: { title: '360 Таблица с текстарией' }
    },
    {
      path: '360-slider',
      name: '360Slider',
      component: () => import('@views/Guide/Screen/S360Slider.vue'),
      meta: { title: '360 Слайдер' }
    },
    {
      path: '360-chebur',
      name: '360Chebur',
      component: () => import('@views/Guide/Screen/S360Chebur.vue'),
      meta: { title: '360 Чебурашка' }
    },
    {
      path: '360-list',
      name: '360List',
      component: () => import('@views/Guide/Screen/S360List.vue'),
      meta: { title: '360 Список оцениваемых' }
    },
    {
      path: 'table',
      name: 'Table',
      component: () => import('@views/Guide/Screen/STable.vue'),
      meta: { title: 'Таблица 1' }
    },
    {
      path: 'dnd',
      name: 'DragAndDrop',
      component: () => import('@views/Guide/Screen/SDragAndDrop.vue'),
      meta: { title: 'drag and drop' }
    },
    {
      path: 'textarea',
      name: 'Textarea',
      component: () => import('@views/Guide/Screen/STextarea.vue'),
      meta: { title: 'textarea' }
    },
    {
      path: 'inputs',
      name: 'Inputs',
      component: () => import('@views/Guide/Screen/SInputs.vue'),
      meta: { title: 'Другие адаптивы' }
    },
    {
      path: 'linear-choice-2',
      name: 'LinearChoice2',
      component: () => import('@views/Guide/Screen/SLinearChoice2.vue'),
      meta: { title: 'Вопрос с ползунком. Пример 2' }
    },
    {
      path: 'linear-choice',
      name: 'LinearChoice',
      component: () => import('@views/Guide/Screen/SLinearChoice.vue'),
      meta: { title: 'Вопрос с ползунком' }
    },
    {
      path: 'radio',
      name: 'Radio',
      component: () => import('@views/Guide/Screen/SRadio.vue'),
      meta: { title: 'Выбор одного в текстовом вопросе' }
    },
    {
      path: 'checkbox',
      name: 'Checkbox',
      component: () => import('@views/Guide/Screen/SCheckbox.vue'),
      meta: { title: 'Множественный выбор' }
    },
    {
      path: 'mostleast',
      name: 'Mostleast',
      component: () => import('@views/Guide/Screen/SMostleast.vue'),
      meta: { title: 'Most-least' }
    },
    {
      path: 'radio-table',
      name: 'RadioTable',
      component: () => import('@views/Guide/Screen/SRadioTable.vue'),
      meta: { title: 'Оценка всех вариантов ответа' }
    },
    {
      path: 'instruction',
      name: 'Instruction',
      component: () => import('@views/Guide/Screen/SInstruction.vue'),
      meta: { title: 'Инструкция' }
    },
    {
      path: 'check',
      name: 'Check',
      component: () => import('@views/Guide/Screen/SCheck.vue'),
      meta: { title: 'Чек системы' }
    },
    {
      path: 'agreement',
      name: 'Agreement',
      component: () => import('@views/Guide/Screen/SAgreement.vue'),
      meta: { title: 'Согласие' }
    },
    {
      path: 'greetings',
      name: 'Greetings',
      component: () => import('@views/Guide/Screen/SGreetings.vue'),
      meta: { title: 'Приветствие' }
    },
    {
      path: 'sections',
      name: 'Sections',
      component: () => import('@views/Guide/Screen/SSections.vue'),
      meta: { title: 'Разделы' }
    },
    {
      path: 'sections-2',
      name: 'Sections 2',
      component: () => import('@views/Guide/Screen/SSections2.vue'),
      meta: { title: 'Разделы 2' }
    },
    {
      path: 'img-and-radio-card',
      name: 'ImgAndRadioCard',
      component: () => import('@views/Guide/Screen/SImgAndRadioCard.vue'),
      meta: { title: 'Вопрос+плашки с ответами' }
    },
    {
      path: 'radio-pictures',
      name: 'RadioPictures',
      component: () => import('@views/Guide/Screen/SRadioPictures.vue'),
      meta: { title: 'Вопросы с картинками' }
    }
  ]
}]
