<template>
    <router-view/>
</template>

<script>

export default {
  name: 'Guide'
}
</script>
