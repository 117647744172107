<template>
  <div
    class="linear-choice"
    :class="classes"
  >
    <div class="linear-choice__left">
      <slot name="textLeft" />
    </div>

    <div class="linear-choice__container">
      <slot />
    </div>

    <div class="linear-choice__right">
      <slot name="textRight" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'LLinearChoice',

  props: {
    type: String
  },

  computed: {
    classes () {
      return {
        [`linear-choice_type_${this.type}`]: !!this.type
      }
    }
  }
}
</script>

<style lang="scss">
@import "~@assets/styles/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.linear-choice {
  $this: &;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  &__container {
    display: flex;
    align-items: center;
    flex: 1 0 auto;
    width: 40%;
    padding: 1rem;

    @include media-breakpoint-between(md, lg) {
      order: 3;
    }

    @include media-breakpoint-down(lg) {
      width: 100%;
      padding: 1rem 0;
    }
  }

  &__left,
  &__right {
    font-size: .875rem;
    width: auto;
    max-width: 20%;

    @include media-breakpoint-between(md, lg) {
      max-width: 45%;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      max-width: 100%;
    }
  }

  &_type {
    &_tablet {
      #{$this}__container {
          width: 100%;
          padding: 1rem 0;
          order: 3;
      }

      #{$this}__left,
      #{$this}__right {
        max-width: 45%;
      }

      #{$this}__right {
        text-align: right;
      }
    }
  }
}
</style>
