<template>
  <div
      :class="classesMain"
      class="l-widget"
  >
    <div
        v-if="this.$slots.header"
        class="l-widget__header"
    >
      <slot name="header"/>
    </div>

    <div
      v-if="showContainer"
      class="l-widget__container"
    >
      <div
          v-if="this.$slots.preContent"
          class="l-widget__pre-content"
      >
        <slot name="preContent"/>
      </div>

      <div class="l-widget__content">
        <slot name="default"/>
      </div>
    </div>

    <div
        v-if="this.$slots.footer"
        class="l-widget__footer"
    >
      <slot name="footer"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LWidget',
  props: {
    fixedHeight: Boolean,
    noIdent: Boolean,
    processing: Boolean,
    vertical: Boolean,
    showContainer: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    classesMain () {
      return {
        'l-widget_type_fixed-height': this.fixedHeight,
        'l-widget_type_processing': this.processing,
        'l-widget_type_no-ident': this.noIdent,
        'l-widget_type_vertical': this.vertical
      }
    }
  }
}
</script>

<style lang="scss">
@import "~@styles/variables";
@import "~@styles/tools";
@import "~bootstrap/scss/mixins/breakpoints";

.l-widget {
  @include radius(lg);
  @include elevation(2);
  flex-wrap: wrap;
  background-color: #fff;
  border: var(--v-sheet-border);

  @include media-breakpoint-down(lg) {
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
  }

  > div:first-of-type {
    border-radius: br(lg) br(lg) 0 0;

    @include media-breakpoint-down(lg) {
      border-radius: br(lg) 0 0 br(lg);
    }
  }

  > div:last-of-type {
    border-radius: 0 0 br(lg) br(lg);

    @include media-breakpoint-down(lg) {
      border-radius: 0 br(lg) br(lg) 0;

      .v-select {
        .vs__dropdown-toggle {
          border-top-right-radius: br(lg);
          border-bottom-right-radius: br(lg);
        }

        &.vs--open {
          .vs__dropdown-toggle {
            border-top-right-radius: br(lg);
            border-bottom-right-radius: 0;
          }
        }
      }
    }
  }

  > div:only-child {
    border-radius: br(lg);

    @include media-breakpoint-down(lg) {
      border-radius: br(lg);
    }
  }

  &__header,
  &__pre-content,
  &__content,
  &__footer {
    padding: 1rem 1.5rem;
    border-radius: inherit;

    @include media-breakpoint-down(lg) {
      padding: .75rem 1rem;
    }

    @include media-breakpoint-down(sm) {
      padding: .5rem 1rem;
    }
  }

  &__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid var(--l-widget-header-border-color);

    @include media-breakpoint-down(lg) {
      order: 2;
      display: flex;
      flex: 0 0 auto;
      justify-content: flex-end;
      min-width: 6rem;
      border-right: 1px solid var(--l-widget-header-border-color);
      border-bottom: none;
    }
  }

  &__container {
    background-color: var(--l-widget-container-bg-color);

    @include media-breakpoint-down(lg) {
      order: 1;
      flex: 1 1 auto;
    }

    //.ie & {
    //  min-height: 200px;
    //}
  }

  &__content {
    height: 100%;
  }

  &_type {
    &_processing {
      > div:first-of-type {
        @include media-breakpoint-down(lg) {
          border-radius: 0 br(lg) br(lg) 0;
        }
      }

      > div:last-of-type {
        @include media-breakpoint-down(lg) {
          border-radius: br(lg) 0 0 br(lg);
        }
      }

      > div:only-child {
        @include media-breakpoint-down(lg) {
          border-radius: br(lg);
        }
      }
    }

    &_fixed-height {
      flex-wrap: nowrap;

      @include media-breakpoint-up(xl) {
        height: 100%;
        display: flex;
        flex-direction: column;

        .ie & {
          height: auto;
        }
      }

      @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
        background-color: transparent;
        box-shadow: none;
        border-radius: 0;
      }

      .l-widget__header {
        order: 1;
        flex: 0 0 auto;

        @include media-breakpoint-down(sm) {
          justify-content: flex-start;
          width: 50%;
          min-width: auto;
          padding: 0 0 .75rem;
        }
      }

      .l-widget__container {
        order: 2;
        flex: 1 1 auto;

        @include media-breakpoint-up(xl) {
          overflow: hidden;
          display: flex;
          flex-direction: column;
        }

        @include media-breakpoint-down(lg) {
          padding: 0;
        }

        @include media-breakpoint-down(sm) {
          order: 3;
          width: 100%;
          min-height: 2.5rem;
          background-color: #fff;
          @include elevation(2);
          @include radius(main, true);

          .v-select {
            .vs__dropdown-toggle {
              border-radius: br(main) !important;
            }
          }
        }

        @include custom-scroll()
      }

      .l-widget__content {
        @include media-breakpoint-up(xl) {
          //flex: 1;
          overflow: auto;
          @include custom-scroll();
        }
      }

      .l-widget__footer {
        order: 3;
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;

        @include media-breakpoint-down(sm) {
          order: 2;
          width: 50%;
          padding: 0 0 .75rem;
          justify-content: flex-end;
        }
      }
    }

    &_no-ident {
      .l-widget {
        &__header,
        &__pre-content,
        &__content,
        &__footer {
          padding: 0;

          @include media-breakpoint-down(lg) {
            padding: 0;
          }

          @include media-breakpoint-down(sm) {
            padding: 0;
          }
        }
      }
    }

    &_vertical {
      @include media-breakpoint-down(lg) {
        flex-direction: column;
      }

      > div:first-of-type {
        @include media-breakpoint-down(lg) {
          border-radius: br(lg) br(lg) 0 0;
        }
      }

      > div:last-of-type {
        @include media-breakpoint-down(lg) {
          border-radius: 0 0 br(lg) br(lg);
        }
      }

      .l-widget {
        &__header {
          @include media-breakpoint-down(lg) {
            justify-content: flex-start;
            order: 1;
            border-bottom: 1px solid cl(border);
            border-right: none;
          }
        }

        &__container {
          @include media-breakpoint-down(lg) {
            order: 2;
          }
        }
      }
    }
  }
}
</style>
