<template>
  <div
    :class="[classes, colorClasses, sizeClasses]"
    @click="onClick"
    @mouseover="onMouseOver"
    @mouseout="onMouseOut"
    class="v-star"
  >
    <v-icon-svg
      class="v-star__icon"
      view-box="0 0 20 20"
      width="20"
      height="20"
    >
      <svg-icon360-star/>
    </v-icon-svg>
  </div>
</template>

<script>
import { VIconSvg } from '@components/base'
import SvgIcon360Star from '@components/icons/360/SvgIcon360Star'
import { Colorable, Sizeable } from '@/mixins'

export default {
  name: 'VStar',

  components: {
    VIconSvg,
    SvgIcon360Star
  },

  mixins: [Colorable, Sizeable],

  props: {
    value: Boolean,
    outlined: Boolean
  },

  computed: {
    classes () {
      return {
        'v-star_is_active': this.value,
        'v-star_is_outlined': this.outlined
      }
    }
  },

  methods: {
    onClick () {
      this.$emit('input', !this.value)
    },

    onMouseOver () {
      this.$emit('mouseover', this.value)
    },

    onMouseOut () {
      this.$emit('mouseout', this.value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@styles/variables";
@import "~@styles/tools";

.v-star {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  // todo use rounded
  //border-radius: br(null);
  border: 1px solid transparent;
  transition: background-color .3s ease, border-color .3s ease;
  user-select: none;

  &:hover {
    background-color: cl(primary);
    border-color: cl(primary);

    .v-star__icon {
      fill: white;
      stroke: white;
    }
  }

  &__icon {
    position: relative;
    top: -1px;
    fill: transparent;
    stroke: cl(primary);
    width: 1.25rem;
    height: 1.25rem;
    transition: fill .3s ease, stroke .3s ease;
    pointer-events: none;
  }

  &_is {
    &_active {
      background-color: cl(primary);
      border-color: cl(primary);

      .v-star__icon {
        fill: white;
        stroke: white;
      }
    }

    &_outlined {
      border-color: cl(primary);
    }
  }

  &_color {
    @each $color in map-keys($theme-colors) {
      &_#{$color} {
        .v-star__icon {
          stroke: cl($color);
        }

        &:hover {
          background-color: cl($color);
          border-color: cl($color);
        }

        &.v-star_is_outlined {
          border-color: cl($color);
        }

        &.v-star_is_active {
          .v-star__icon {
            fill: white;
            stroke: white;
          }

          background-color: cl($color);
          border-color: cl($color);
        }
      }
    }
  }

  &_size {
    @each $el-size in map-keys($spacers) {
      $el-local-size: map-get($spacers, $el-size);

      &_#{$el-size} {
        width: $el-local-size;
        height: $el-local-size;

        .v-star__icon {
          width: $el-local-size - 1rem;;
          height: $el-local-size - 1rem;
        }
      }
    }
  }
}
</style>
