<template>
  <button
    v-ripple="this.ripple"
    :aria-expanded="this.isOpen"
    :class="mainClasses"
    :tabindex="this.isDisabled ? -1 : null"
    @click="onClick"
    type="button"
    class="v-expansion-panel-header"
  >
    <span class="v-expansion-panel-header__text">
      <slot name="default" :open="isOpen"/>
    </span>

    <span
      v-if="!this.noIcon"
      class="v-expansion-panel-header__icon"
    >
      <slot name="icon" :isOpen="isOpen">
        <v-icon-svg
          class="a-flip-y svg-stroke-primary"
          :class="{'a-flip-y_active': isOpen && !disableIconRotate}"
          fill="none"
          view-box="0 0 11 5"
          title="svg-icon-chevron"
          width="12"
          height="12"
        >
          <svg-icon-chevron/>
        </v-icon-svg>
      </slot>
    </span>
  </button>
</template>

<script>
import { VIconSvg } from '@components/base'
import SvgIconChevron from '@components/icons/SvgIconChevron'
import { Ripple } from '@directives'

export default {
  name: 'VExpansionPanelHeader',

  components: {
    VIconSvg,
    SvgIconChevron
  },

  directives: {
    Ripple
  },

  inject: ['expansionPanel'],

  props: {
    disableIconRotate: Boolean,
    inline: Boolean,
    noIcon: Boolean,
    ripple: {
      type: [Boolean, Object],
      default: false
    }
  },

  computed: {
    mainClasses () {
      return {
        'v-expansion-panel-header_is_open': this.isOpen,
        'v-expansion-panel-header_type_inline': this.inline,
        'v-expansion-panel-header_without_icon': this.noIcon
      }
    },
    isOpen () {
      return this.expansionPanel.isOpen
    },
    isDisabled () {
      return this.expansionPanel.isDisabled
    },
    isReadonly () {
      return this.expansionPanel.isReadonly
    }
  },

  created () {
    this.expansionPanel.registerHeader(this)
  },

  beforeDestroy () {
    this.expansionPanel.unregisterHeader()
  },

  methods: {
    onClick (e) {
      this.$emit('click', e)
    }
  }
}
</script>

<style lang="scss">
@import "~@styles/variables";
@import "~@styles/tools";

$expansion-panel-border: 1px solid cl(default);

.v-expansion-panel-header {
  display: flex;
  align-items: center;
  padding: 1rem;
  margin: 0;
  font-family: var(--font-primary);
  font-weight: 600;
  font-size: 1rem;
  border: $expansion-panel-border;
  border-bottom: 0;
  background: none;
  cursor: pointer;

  &__text {
    flex: 1 0 100%;
    text-align: left;
    padding-right: 1rem;
  }

  &__icon {
    flex: 0 0 auto;
    text-align: right;
    user-select: none;
  }

  &_type {
    &_inline {
      .v-expansion-panel-header__text {
        flex: 0 0 auto;
      }
    }
  }

  &_without {
    &_icon {
      .v-expansion-panel-header__text {
        padding-right: 0;
      }
    }
  }
}
</style>
