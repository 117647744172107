const { VUE_APP_AUTH_PATH, VUE_APP_PORTFOLIO } = process.env

export default [
  {
    path: `${VUE_APP_AUTH_PATH}`,
    name: 'Auth',
    component: () => import('@/views/Auth')
  },
  {
    path: `${VUE_APP_PORTFOLIO}`,
    name: 'Portfolio',
    component: () => import('@/views/Portfolio/Portfolio')
  }
]
