<template>
  <div
    :class="mainClasses"
    :style="mainStyles"
    class="v-avatar"
  >
    <slot></slot>
  </div>
</template>

<script>
import {
  Colorable,
  Roundable,
  Sizeable
} from '@/mixins'

export default {
  name: 'VAvatar',

  mixins: [
    Colorable,
    Roundable,
    Sizeable
  ],

  props: {
    img: String
  },

  computed: {
    mainClasses () {
      return {
        'v-avatar_has_img': this.img,
        ...this.colorClasses,
        ...this.sizeClasses,
        ...this.roundedClasses
      }
    },
    mainStyles () {
      return {
        'background-image': this.img && `url(${this.img})`
      }
    }
  }
}
</script>

<style lang="scss">
@import "~@styles/variables";
@import "~@styles/tools";

.v-avatar {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  border-radius: 1000rem;
  min-width: 1.5rem;
  height: 1.5rem;
  color: #FFFFFF;
  background-color: cl(primary);

  &_color {
    @each $color in map-keys($theme-colors) {
      &_#{$color} {
        background-color: cl($color);
      }
    }
  }

  &_size {
    @each $el-size in map-keys($spacers) {
      &_#{$el-size} {
        min-width: map-get($spacers, $el-size);
        height: map-get($spacers, $el-size);
      }
    }
  }

  &_has {
    &_img {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}
</style>
