<template>
  <div>
    <v-star
      v-if="!isShow"
      size="40"
      :value="!!value"
      @input="toggleStarsVisibility"
      outlined
    />

    <star-line
      v-else
      size="40"
      :value="value"
      :variant="variant"
      @input="onInput"
      class="star-line_mode_replace"
    />
  </div>
</template>

<script>
import { VStar } from '@components/base'
import { StarLine } from '@components/features'

export default {
  name: 'StarLineReplace',

  components: {
    StarLine,
    VStar
  },

  props: {
    value: [String, Number],
    variant: Array
  },

  data () {
    return {
      isShow: false
    }
  },

  methods: {
    onInput (newValue) {
      this.$emit('input', newValue)
      this.toggleStarsVisibility()
    },

    toggleStarsVisibility () {
      this.isShow = !this.isShow
    }
  }
}
</script>

<style lang="scss">
.star-line_mode_replace {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: .5rem;

  .star-line__item {
    display: flex;
    justify-content: center;
    flex: 1 0 20%;
  }
}
</style>
