<template>
  <r-portal>
    <transition name="modal-animation">
      <div
        v-if="isShown"
        :class="classes"
        class="v-modal-store"
        @keydown.esc="onEsc"
      >
        <r-focus-trap>
          <component
            :is="component.name"
            v-bind="component.props"
            v-on="component.emits"
          />
        </r-focus-trap>

        <div
          class="v-modal-store__mask"
          @click.self="maskOnClick"
        />
      </div>
    </transition>
  </r-portal>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import {
  RFocusTrap,
  RPortal
} from '@components/rednderless'
import * as Modals from '@components/modals'

export default {
  name: 'VModalStore',

  components: {
    RFocusTrap,
    RPortal,
    ...Modals
  },

  computed: {
    classes () {
      return {
        'v-modal-store_type_click-outside': this.options.clickOutsideToClose
      }
    },
    ...mapState('modal', [
      'isShown',
      'component',
      'options'
    ])
  },

  methods: {
    ...mapActions('modal', [
      'hideModal'
    ]),
    maskOnClick () {
      if (this.options.clickOutsideToClose) {
        this.hideModal()
      }
    },
    onEsc () {
      if (this.options.closeOnEsc) {
        this.hideModal()
      }
    }
  }
}
</script>

<style lang="scss">
@import "~@assets/styles/variables";
@import "~@assets/styles/tools/z-index.scss";

.v-modal-store {
  @include z-index(modal);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  &__mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    transition: opacity .3s ease;
  }

  &_type {
    &_click-outside {
      .v-modal__mask {
        cursor: pointer;

        &:hover {
          background-color: rgba(0, 0, 0, 0.8);
        }
      }
    }
  }
}

.modal-animation {
  &-enter,
  &-leave-active {
    .v-modal-store {
      &__mask {
        opacity: 0;
      }
    }
  }
}
</style>
