import { createPopper } from '@popperjs/core'
import { getRandomUuid } from '@utils/helpers'

const DEFAULTS = {
  baseClass: 'd-tooltip',
  modifiers: {},
  value: ''
}
const POPPER_DEFAULTS = {
  placement: 'bottom',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, 8]
      }
    },
    {
      name: 'preventOverflow',
      options: {
        padding: 16
      }
    }
  ]
}

class Tooltip {
  constructor (el, options = {}) {
    this._uuid = getRandomUuid()

    this._popperOptions = POPPER_DEFAULTS
    this._options = {
      ...DEFAULTS,
      ...options
    }

    this._targetEl = el
    this._contentEl = this._createTooltipElement(this._options)
    this._instance = createPopper(
      this._targetEl,
      this._contentEl,
      this._popperOptions
    )

    this.setup()
  }

  setup () {
    this.setContent(this._options.value)
    this._instance.setOptions((options) => ({
      ...options,
      modifiers: [
        ...options.modifiers,
        { name: 'eventListeners', enabled: false }
      ]
    }))

    this._setEvents()
  }

  destroy () {
    this._uuid = null
    this._removeEvents()
    this._removeContent()
  }

  show () {
    if (!this._uuid) return

    document.querySelector('body').appendChild(this._contentEl)

    this._instance.setOptions((options) => ({
      ...options,
      modifiers: [
        ...options.modifiers,
        { name: 'eventListeners', enabled: true }
      ]
    }))

    this._instance.update()
  }

  hide () {
    this._removeContent()

    this._instance.setOptions((options) => ({
      ...options,
      modifiers: [
        ...options.modifiers,
        { name: 'eventListeners', enabled: false }
      ]
    }))
  }

  setContent (content) {
    this._contentEl.querySelector(`.${this._options.baseClass}__content`).textContent = `${content}`
  }

  _removeContent () {
    if (this._contentEl && this._contentEl.parentNode) {
      this._contentEl.parentNode.removeChild(this._contentEl)
    }
  }

  _setEvents () {
    this._targetEl.addEventListener('mouseenter', this.show.bind(this))
    this._targetEl.addEventListener('mouseleave', this.hide.bind(this))
  }

  _removeEvents () {
    this._targetEl.removeEventListener('mouseenter', this.show)
    this._targetEl.removeEventListener('mouseleave', this.hide)
  }

  _createTooltipElement (options) {
    // wrapper
    const popper = document.createElement('section')
    popper.setAttribute('id', `tooltip-${this._uuid}`)
    popper.setAttribute('class', `${options.baseClass}`)

    // make arrow
    const arrow = document.createElement('div')
    arrow.setAttribute('class', `${options.baseClass}__arrow`)
    arrow.setAttribute('data-popper-arrow', '')
    popper.appendChild(arrow)

    // make content container
    const content = document.createElement('div')
    content.setAttribute('class', `${options.baseClass}__content`)
    popper.appendChild(content)

    return popper
  }
}

export default {
  bind (el, binding) {
    const { value, modifiers } = binding

    el.tooltip = new Tooltip(el, {
      value,
      modifiers
    })
  },

  update (el, { value }) {
    el.tooltip.setContent(value)
  },

  unbind (el) {
    el.tooltip.destroy()
  }
}
