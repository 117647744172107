<template>
  <section
    :class="loadingClasses"
    class="main-container"
  >
    <header
      v-if="showHeader"
      class="main-container__header"
    >
      <slot name="header"/>
    </header>

    <main
      class="main-container__content"
      :style="containerStyles"
    >
      <slot/>
    </main>

    <footer
      v-if="showFooter"
      class="main-container__footer"
    >
      <slot name="footer"/>
    </footer>

    <!-- модалки, снэкбары, тултипы и тп -->
    <slot name="ui"/>
  </section>
</template>

<script>
import { Loadable } from '@mixins'

export default {
  name: 'LMain',
  mixins: [Loadable],

  props: {
    showHeader: {
      type: Boolean,
      defaults: true
    },
    showFooter: {
      type: Boolean,
      defaults: true
    },
    containerStyles: {
      type: Object
    }
  }
}
</script>

<style lang="scss">
@import "~@styles/variables/index.scss";
@import "~@styles/tools/index.scss";

.main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;

  &__header {
    color: var(--l-main-header-text-color);
    background-color: var(--l-main-header-bg-color);
    border-bottom: 1px solid cl(border);
  }

  &__content {
    flex-grow: 1;
    flex-shrink: 0;
    background-color: var(--l-main-content-bg-color);
    background-repeat: repeat;
  }

  &__footer {
    color: var(--l-main-footer-text-color);
    background-color: var(--l-main-footer-bg-color);
    border-top: 1px solid cl(border);
  }
}
</style>
