<template>
  <r-portal>
    <transition name="snackbar-animation">
      <section
        v-if="isShown"
        :class="classes"
        class="v-snackbar-store"
      >
        <div class="v-snackbar-store__container">
          <div
            v-mutate="resetTimer"
            class="v-snackbar-store__content"
            v-html="content"
          />
          <div class="v-snackbar-store__action">
            <slot name="action"/>
          </div>
        </div>
      </section>
    </transition>
  </r-portal>
</template>

<script>
import { Mutate } from '@directives'
import { RPortal } from '@components/rednderless'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'VSnackbar',

  directives: {
    Mutate
  },

  components: {
    RPortal
  },

  data () {
    return {
      timer: null
    }
  },

  computed: {
    ...mapState('snackbar', ['isShown', 'content', 'options']),
    classes () {
      return {
        [`v-snackbar-store_position_${this.options.position}`]: !!this.options.position,
        [`v-snackbar-store_color_${this.options.color}`]: !!this.options.color
      }
    }
  },

  watch: {
    isShown () {
      this.resetTimer()
    }
  },

  methods: {
    ...mapActions('snackbar', ['showSnackbar', 'hideSnackbar']),

    resetTimer () {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }

      if (this.isShown && !this.options.endless) {
        this.timer = setTimeout(this.hideSnackbar, this.options.timeout)
      }
    }
  }
}
</script>

<style lang="scss">
@import "~@styles/variables";
@import "~@styles/tools";

.v-snackbar-store {
  @include z-index(snackbar);
  position: fixed;
  bottom: 0;
  padding: .5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  transition: opacity 2.5s ease;

  &__container {
    display: flex;
    align-items: center;
    padding: 1rem 1.5rem;
    margin-top: .5rem;
    color: #FFFFFF;
    font-size: .75rem;
    background-color: #333;
    @include elevation(2);
    @include radius(null)
  }

  &_position {
    &_left {
      justify-content: flex-start;
    }

    &_right {
      justify-content: flex-end;
    }
  }

  &_color {
    @each $color in map-keys($theme-colors) {
      &_#{$color} {
        > .v-snackbar-store__container {
          background-color: cl($color);
        }
      }
    }
  }
}

.snackbar-animation {
  &-enter-active {
    opacity: 1;
  }

  &-leave-active {
    opacity: 0;
  }
}
</style>
