<template>
  <li
    class="v-list-item"
    :class="classes"
  >
    <component
      v-ripple="ripple"
      :is="!link ? 'li' : 'router-link'"
      :to="link"
      :active-class="'v-list-item_is_active'"
      @click="onClick"
      class="v-list-item__container"
    >
      <div
        v-if="this.$slots.start"
        class="v-list-item__start"
      >
        <slot name="start"></slot>
      </div>

      <div class="v-list-item__content">
        <slot></slot>
      </div>

      <div
        v-if="this.$slots.end"
        class="v-list-item__end"
      >
        <slot name="end"></slot>
      </div>

      <div
        v-if="this.$slots.submenu"
        class="v-list-item__submenu"
      >
        <slot name="submenu"></slot>
      </div>
    </component>
  </li>
</template>

<script>
import { Ripple } from '@directives'

export default {
  name: 'VListItem',

  directives: {
    Ripple
  },

  props: {
    ripple: {
      type: [Boolean, Object],
      default: () => ({ class: 'text-color-primary' })
    },
    link: String,
    clickable: Boolean,
    hoverable: Boolean,
    active: Boolean,
    noIndent: Boolean
  },

  computed: {
    classes () {
      return {
        'v-list-item_is_active': this.active,
        'v-list-item_type_link': this.link,
        'v-list-item_type_clickable': this.clickable,
        'v-list-item_type_hoverable': this.hoverable,
        'v-list-item_type_no-indent': this.noIndent
      }
    }
  },

  methods: {
    onClick (e) {
      this.$emit('click', e)
    }
  }
}
</script>

<style lang="scss">
@import "~@styles/variables";
@import "~@styles/tools";

.v-list-item {
  position: relative;
  margin: 0;

  &__container {
    display: flex;
    align-items: center;
    transition: border-color .4s ease, background-color .3s ease;

    &:hover {
      > .v-list-item__submenu {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &__start,
  &__content,
  &__end {
    padding: .5rem 1rem;
  }

  &__content {
    flex-grow: 1;
  }

  &__submenu {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: -.5rem;
    left: 100%;
    width: 100%;
    min-width: 20rem;
    max-width: 40rem;
    background-color: #fff;
    border-radius: 0 br(null) br(null) br(null);
    overflow: hidden;
    transition: opacity .6s ease;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(cl(rgb-primary), .1);
    }

    > .v-list {
      height: 100%;
      min-height: 20rem;
      max-height: 50vh;
      overflow: auto;
      @include custom-scroll()
    }
  }

  &_type {
    &_link {
      cursor: pointer;

      .v-list-item__container:hover {
        background-color: rgba(cl(rgb-primary), .1);
      }
    }

    &_clickable {
      cursor: pointer;
    }

    &_hoverable {
      .v-list-item__container:hover {
        background-color: rgba(cl(rgb-primary), .1);
      }
    }

    &_no-indent {
      .v-list-item__start,
      .v-list-item__content,
      .v-list-item__end {
        padding: 0;
      }
    }
  }

  &_is {
    &_active {
      background-color: rgba(cl(rgb-primary), .1);
      cursor: default;

      .v-list-item__container:hover {
        //background-color: transparent;
      }
    }
  }
}
</style>
