<template>
  <div
    v-show="isOpen"
    :class="mainClasses"
    class="v-expansion-panel-content"
  >
    <slot :open="isOpen"/>
  </div>
</template>

<script>

export default {
  name: 'VExpansionPanelContent',

  inject: ['expansionPanel'],

  computed: {
    mainClasses () {
      return {
        'v-expansion-panel-content_is_open': this.isOpen
      }
    },
    isOpen () {
      return this.expansionPanel.isOpen
    }
  },

  created () {
    this.expansionPanel.registerContent(this)
  },

  beforeDestroy () {
    this.expansionPanel.unregisterContent()
  }
}
</script>

<style lang="scss">
@import "~@styles/variables";
@import "~@styles/tools";

$expansion-panel-border: 1px solid cl(default);

.v-expansion-panel-content {
  padding: 1rem;
  border: $expansion-panel-border;
  border-bottom: 0;
}
</style>
