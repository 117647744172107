<template>
  <r-portal>
    <transition name="modal-animation">
      <div
        v-if="value"
        :class="classes"
        class="v-modal"
      >
        <r-focus-trap>
          <div
            @keydown.esc="onEsc"
            class="v-modal__container"
          >
              <div
                v-if="this.$slots.header"
                class="v-modal__header"
              >
                <slot name="header"/>
              </div>

              <div class="v-modal__window">
                <div class="v-modal__content">
                  <slot></slot>
                </div>
              </div>

              <div
                v-if="this.$slots.footer"
                class="v-modal__footer"
              >
                <slot name="footer"/>
              </div>
          </div>
        </r-focus-trap>

        <div
          class="v-modal__mask"
          @click.self="maskOnClick"
        />
      </div>
    </transition>
  </r-portal>
</template>

<script>
import {
  RFocusTrap,
  RPortal
} from '@components/rednderless'

export default {
  name: 'VModal',

  components: {
    RFocusTrap,
    RPortal
  },

  props: {
    closeOnEsc: {
      type: Boolean,
      default: true
    },
    clickOutsideToClose: {
      type: Boolean,
      default: true
    },
    noIndent: Boolean,
    value: Boolean
  },

  computed: {
    classes () {
      return {
        'v-modal_type_click-outside': this.clickOutsideToClose,
        'v-modal_type_no-indent': this.noIndent
      }
    }
  },

  methods: {
    close () {
      this.$emit('input', false)
    },
    maskOnClick () {
      if (this.clickOutsideToClose) {
        this.close()
      }

      this.$emit('clicked-outside')
    },
    onEsc () {
      if (this.closeOnEsc) {
        this.close()
      }
    }
  }
}
</script>

<style lang="scss">
@import "~@assets/styles/variables";
@import "~@assets/styles/tools/scroll";
@import "~@assets/styles/tools/elevation.scss";
@import "~@assets/styles/tools/radius.scss";
@import "~@assets/styles/tools/z-index.scss";
@import "~bootstrap/scss/mixins/breakpoints";

.v-modal {
  @include z-index(modal);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  &__container {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-height: 100%;
    padding: 1rem 0;
    background-color: #fff;
    transition: transform .3s ease;
    @include elevation(10);
    @include radius(lg);

    @include media-breakpoint-up(lg) {
      max-width: 70%;
    }

    @include media-breakpoint-up(xl) {
      max-width: 50%;
    }
  }

  &__header {
    flex: 0 0 auto;
    margin: 1rem 2rem;
  }

  &__window {
    overflow-y: auto;
    margin: 1rem 0;
    @include custom-scroll()
  }

  &__content {
    padding: 0 2rem;

    > p:last-child {
      margin-bottom: 0;
    }
  }

  &__footer {
    flex: 0 0 auto;
    margin: 1rem 2rem;
  }

  &__mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    transition: opacity .3s ease, background-color 1s ease;
  }

  &_type {
    &_click-outside {
      .v-modal__mask {
        cursor: pointer;

        &:hover {
          background-color: rgba(0, 0, 0, 0.4);
        }
      }
    }

    &_no-indent {
      .v-modal__container,
      .v-modal__header,
      .v-modal__content,
      .v-modal__window,
      .v-modal__footer {
        padding: 0;
        margin: 0;
      }
    }
  }
}

.modal-animation {
  &-enter,
  &-leave-active {
    opacity: 0;

    .modal__container {
      transform: scale(1.1);
    }
  }
}

</style>
