<template>
  <ul
    :class="classes"
    class="v-list"
  >
    <slot></slot>
  </ul>
</template>

<script>
export default {
  name: 'VList',

  props: {
    underlined: Boolean,
    table: Boolean
  },

  computed: {
    classes () {
      return {
        'v-list_type_table': this.table,
        'v-list_type_underlined': this.underlined
      }
    }
  }
}
</script>

<style lang="scss">
@import "~@styles/variables";
@import "~@styles/tools";

.v-list {
  list-style: none;
  margin: 0;
  padding: .5rem 0;

  &_type {
    &_underlined {
      .v-list-item {
        border-bottom: 1px solid rgba(cl(rgb-primary), .2);

        &_is {
          &_active {
            border-bottom-color: var(--color-primary);
          }

          &_clickable {
            &:hover {
              border-bottom-color: var(--color-primary);
            }
          }
        }
      }
    }

    &_table {
      display: table;
      width: 100%;

      > .v-list-item {
        display: table-row;

        > .v-list-item__start,
        > .v-list-item__content,
        > .v-list-item__end {
          display: table-cell;
          vertical-align: middle;
        }
      }
    }
  }
}
</style>
