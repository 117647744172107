/**
 * Get the distance of the element from the top/left of the page
 * @param {HTMLDivElement} elem - element
 * @returns {{x: number, y: number}} - element position from the page
 */
export default function getOffset (elem) {
  const doc = document.documentElement
  const body = document.body
  const rect = elem.getBoundingClientRect()

  return {
    y: rect.top + (window.pageYOffset || doc.scrollTop) - (doc.clientTop || body.clientTop || 0),
    x: rect.left + (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || body.clientLeft || 0)
  }
}
