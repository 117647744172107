import { mapState } from 'vuex'
import { addClassesToBody, clearBodyClassesByList } from '@utils/helpers'

export default {
  data () {
    return {
      themePrefix: 'theme'
    }
  },

  mounted () {
    this.updateTheme()
  },

  computed: {
    ...mapState({
      currentTheme: state => state.theme.current,
      allThemes: state => state.theme.all
    })
  },

  watch: {
    currentTheme () {
      this.updateTheme()
    }
  },

  methods: {
    updateTheme () {
      clearBodyClassesByList(this.allThemes, this.themePrefix)
      addClassesToBody([this.currentTheme], this.themePrefix)
    }
  }
}
