/**
 * Convert rgb color to spectrum range
 * @param {string} rgb - rgb color
 * @param {number} currIndex - current value of range
 * @param {number} minIndex - minimum value of range
 * @param {number} maxIndex - maximum value of range
 * @param {number} minOpacity - minimum opacity of spectrum
 * @param {number} maxOpacity - maximum opacity of spectrum
 * @returns {string} - rgba color in accordance with current value and range
 */
export default function convertToColorSpectrum ({
  rgb = '0,0,0',
  currIndex,
  minIndex = 0,
  maxIndex,
  minOpacity = 0,
  maxOpacity = 1
}) {
  const range = maxIndex - minIndex
  const oRange = maxOpacity - minOpacity
  const opacity = ((currIndex - minIndex) * oRange / range) + minOpacity
  return `rgba(${rgb}, ${opacity.toFixed(2)})`
}
