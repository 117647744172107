export default {
  methods: {
    /**
     * Отправка postMessage в родительское окно
     * @param {object} [params] - данные, которые нужно отправить
     * @param {string} [origin='*'] - origin сайта для проверки, если необходимо
     */
    frameable_SendPostMessageToParent (params, origin = '*') {
      this.$nextTick(() => {
        window.parent.postMessage(params, origin)
      })
    }
  }
}
