<template>
  <section class="drm">
    <div
      ref="leftColumn"
      class="drm__item"
    >
      <draggable
        tag="ul"
        :style="{
          minHeight: `${minHeight}px`
        }"
        :list="variants"
        group="questions"
        animation="200"
        class="dr-list"
        role="list"
      >
        <li
          v-for="item in variants"
          :key="item.code"
          :class="itemClasses"
          class="dr-list__item"
          role="listitem"
        >
          <div
            v-html="item.text"
            class="dr-list__text"
          />
        </li>
      </draggable>
    </div>

    <div
      ref="rightColumn"
      class="drm__item"
    >
      <draggable
        tag="ul"
        v-for="(group, groupIndex) in answers"
        :key="group.name"
        :list="group.list"
        :class="{'drm-list_has_item': group.list.length > 0}"
        :data-group-name="group.name"
        group="questions"
        animation="200"
        class="drm-list"
        role="list"
        @change="setData($event, groupIndex)"
      >
        <li
          v-for="item in group.list"
          :key="item.code"
          :class="itemClasses"
          class="drm-list__item"
          role="listitem"
        >
          <div
            v-html="item.text"
            class="drm-list__text"
          />
        </li>
      </draggable>
    </div>
  </section>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: 'DragRangingMultiple',

  components: {
    draggable
  },

  props: {
    answers: {
      type: Array,
      defaults: []
    },
    error: Boolean,
    variants: {
      type: Array,
      defaults: []
    }
  },

  data () {
    return {
      minHeight: 0
    }
  },

  computed: {
    itemClasses () {
      return {
        'dr-list__item_is_error': this.error
      }
    }
  },

  mounted () {
    this.recalculateSize()
  },

  methods: {
    setData () {
      this.$emit('change', JSON.parse(JSON.stringify(this.answers)))
    },
    recalculateSize () {
      const { leftColumn, rightColumn } = this.$refs
      const lHeight = leftColumn.offsetHeight
      const rHeight = rightColumn.offsetHeight

      this.minHeight = lHeight > rHeight
        ? lHeight
        : rHeight
    }
  }
}
</script>

<style lang="scss">
@import "~@styles/variables";
@import "~@styles/tools";
@import "~bootstrap/scss/mixins/breakpoints";

$label-transition: transform .1s map-get($transition, "fast-in-fast-out"), color .1s map-get($transition, "fast-in-fast-out"), font-size .1s map-get($transition, "fast-in-fast-out");

.drm {
  display: flex;
  justify-content: space-between;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }

  &__item {
    flex: 0 1 48%;

    @include media-breakpoint-down(sm) {
      min-height: 4rem !important;

      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }
  }
}

.dr-list {
  position: relative;
  height: 100%;
  list-style: none;
  margin: 0;
  padding: 0;

  &__item {
    padding: .5rem;
    background-color: #fff;
    border: 1px solid cl(border);
    border-radius: 4px;
    user-select: none;
    cursor: grab;

    &:not(:last-child) {
      margin-bottom: .5rem;
    }

    &_is_error {
      border-color: cl(danger);
    }
  }
}

.drm-list {
  position: relative;
  height: auto;
  min-height: 3rem;
  margin: 0;
  padding: .25rem;
  list-style: none;
  border: 1px dashed rgba(var(--color-rgb-border), 0.5);
  border-radius: 4px;

  &::before {
    display: block;
    padding: .5rem 0;
    content: attr(data-group-name);
    position: relative;
    max-width: calc(100% - .5rem);
    color: rgba(var(--v-sheet-color-rgb-primary), 0.5);
    transform: translate(0, 0);
    transition: $label-transition;
    transform-origin: 0 0;
  }

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  &:not(:empty) {
    border-color: cl(primary);

    &:before {
      overflow: hidden;
      position: absolute;
      max-width: 100%;
      color: cl(primary);
      font-size: .75rem;
      transform: translate(-.25rem, -1.9rem) !important;
      padding: 0;
      top: .75rem;
      left: .25rem;
      white-space: nowrap;
      text-align: left;
      text-overflow: ellipsis;
      pointer-events: none;
      user-select: none;
    }
  }

  &__item {
    padding: .5rem;
    border-radius: 4px;
    background-color: rgba(var(--v-sheet-color-rgb-primary), 0.1);
    border: 1px solid rgba(var(--v-sheet-color-rgb-primary), 0.5);
    user-select: none;
    cursor: grab;

    &:not(:last-child) {
      margin-bottom: .5rem;
    }
  }
}
</style>
