<template>
  <tr
    :class="classes"
    @click="$emit('click', $event)"
    class="v-table-tr"
  >
    <slot/>
  </tr>
</template>

<script>
export default {
  name: 'VTableTr',

  props: {
    active: Boolean,
    error: Boolean,
    hovered: Boolean
  },

  computed: {
    classes () {
      return {
        'v-table-tr_is_active': this.active,
        'v-table-tr_is_error': this.error,
        'v-table-tr_type_hovered': this.hovered
      }
    }
  }
}
</script>

<style lang="scss">
@import "~@styles/variables";
@import "~@styles/tools";

.v-table-tr {
  &:not(:last-of-type) {
    .v-table-td {
      border-bottom: 1px solid cl(border);
    }
  }

  &_is {
    &_active {
        background-color: var(--v-table-active-color);
    }

    &_error {
      background-color: #fae7e7 !important;
    }
  }

  &_type {
    &_hovered {
      &:hover {
        background-color: var(--v-table-active-color);
      }
    }
  }
}
</style>
