/**
 * Attaches the desired ending
 * @param {string} str - value
 * @param {string} unit - ending
 * @returns {string|undefined} - value with ending
 */
export default function convertToUnit (str, unit = 'px') {
  if (str == null || str === '') {
    return undefined
  } else if (isNaN(+str)) {
    return String(str)
  } else {
    return `${Number(str)}${unit}`
  }
}
