<template>
  <section
      :class="classes"
      class="v-tabs"
  >
    <div
        class="v-tabs-list"
        role="tablist"
    >
      <button
          v-if="pagination.isShow"
          @click="goToPrewTab"
          class="v-tabs-list__button rotate-180"
      >
        <v-icon
            stroke="primary"
            name="chevron-2"
            size="16"
        />
      </button>

      <div
          ref="listGroup"
          class="v-tabs-list__group"
      >
        <div
            ref="listContent"
            class="v-tabs-list__group-content"
            :style="groupContentStyles"
        >
          <button
              v-for="(tab, name, index) in VTabs.items"
              v-ripple="computedRipple"
              :key="name"
              :class="{'v-tabs-list__item_is_active': name === VTabs.activeTabId}"
              :title="tab.label"
              :aria-selected="name === VTabs.activeTabId"
              @click="setTabById(name)"
              tabindex="0"
              role="tab"
              class="v-tabs-list__item"
          >
            <slot
                :name="`slot-${index}`"
                :is-active="name === VTabs.activeTabId"
            >
              {{ tab.label }}
            </slot>
          </button>
        </div>
      </div>

      <button
          v-if="pagination.isShow"
          @click="goToNextTab"
          class="v-tabs-list__button"
      >
        <v-icon
            stroke="primary"
            name="chevron-2"
            size="16"
        />

      </button>
    </div>

    <div class="v-tabs__container">
      <slot/>
    </div>
  </section>
</template>

<script>
import { POSITION_X } from '@/constants/components'
import { VIcon } from '@components/base'
import { Ripple } from '@directives'
import { Sizeable } from '@mixins'

export default {
  name: 'VTabs',

  directives: {
    Ripple
  },

  mixins: [
    Sizeable
  ],

  components: {
    VIcon
  },

  provide () {
    return {
      VTabs: this.VTabs
    }
  },

  props: {
    position: {
      type: String,
      validator: (type) => POSITION_X.includes(type),
      default: 'left'
    },
    value: {
      type: Number,
      validator: (index) => index >= 0,
      default: 0
    },
    outlined: Boolean
  },

  data: () => ({
    VTabs: {
      activeTabId: null,
      items: {}
    },
    pagination: {
      isShow: true,
      buttons: {
        prev: false,
        next: true
      },
      currentShift: 0,
      stepWidth: 0,
      limiter: 0
    }
  }),

  computed: {
    classes () {
      return {
        'v-tabs_type_outlined': this.outlined,
        'v-tabs_type_paginate': this.pagination.isShow,
        'v-tabs_paginate_prev-btn': this.pagination.buttons.prev,
        'v-tabs_paginate_next-btn': this.pagination.buttons.next,
        [`v-tabs_position_${this.position}`]: this.position,
        ...this.sizeClasses
      }
    },
    groupContentStyles () {
      return {
        transform: `translateX(${this.pagination.currentShift}px)`
      }
    },
    idArray () {
      return Object.keys(this.VTabs.items)
    },
    currentIndex () {
      return this.idArray.indexOf(this.VTabs.activeTabId)
    },
    computedRipple () {
      return this.pagination.isShow ? { class: 'text-color-primary' } : false
    }
  },

  watch: {
    value (newIndex) {
      this.setTabByIndex(newIndex)
    }
  },

  mounted () {
    // установка активного таба
    let firstTab = this.value

    if (this.$route.query.print === 'print') {
      firstTab = 1
    }

    this.setTabByIndex(firstTab)
    new ResizeObserver(this.doResizeObserver).observe(this.$refs.listContent)
  },

  methods: {
    doResizeObserver () {
      this.initPagination()
    },
    initPagination () {
      // инициализация пагинации
      // this.setTabByIndex(this.value)

      this.$nextTick(() => {
        // при переходе на другую старницу this.$refs.listGroup уже нет,
        // а $nextTick срабатывает
        if (!this.$refs.listGroup) return

        this.pagination.stepWidth = this.$refs.listGroup.clientWidth - 100
        this.pagination.limiter = this.$refs.listContent.clientWidth - this.$refs.listGroup.clientWidth
        this.pagination.isShow = this.pagination.limiter > 0

        this.pagination.currentShift = 0
      })
    },

    /**
     * Установка активного таба по ID
     * @param {number | string} id - id нужного таба
     */
    setTabById (id) {
      this.VTabs.activeTabId = id

      // для lazyload
      if (this.VTabs.items[id] && !this.VTabs.items[id].isVisited) {
        this.VTabs.items[id].isVisited = true
      }

      this.$emit('input', this.currentIndex)
    },

    /**
     * Установка активного таба по индексу
     * @param {number} index - index нужного таба
     */
    setTabByIndex (index) {
      this.setTabById(this.idArray[index])
    },

    /** Переключение на предыдущий таб */
    goToPrewTab () {
      if (this.pagination.isShow) {
        if (this.pagination.currentShift <= 0) {
          this.pagination.currentShift += this.pagination.stepWidth
          this.pagination.buttons.next = true
        }

        if (this.pagination.currentShift >= 0) {
          this.pagination.currentShift = 0
          this.pagination.buttons.prev = false
        }

        return
      }

      const id = this.currentIndex === 0
        ? this.idArray[this.idArray.length - 1]
        : this.idArray[this.currentIndex - 1]

      this.setTabById(id)
    },

    /** Переключение на следующий */
    goToNextTab () {
      if (this.pagination.isShow) {
        if (this.pagination.currentShift <= 0) {
          this.pagination.currentShift -= this.pagination.stepWidth
          this.pagination.buttons.prev = true
        }

        if (this.pagination.currentShift <= -this.pagination.limiter) {
          this.pagination.currentShift = -this.pagination.limiter
          this.pagination.buttons.next = false
        }

        return
      }

      const id = this.currentIndex === this.idArray.length - 1
        ? this.idArray[0]
        : this.idArray[this.currentIndex + 1]

      this.setTabById(id)
    }
  }
}
</script>

<style lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";
@import "~@styles/variables";
@import "~@styles/tools";

.v-tabs {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  &__container {
    width: 100%;
    background-color: #fff;
    @include elevation(2);
    border: 1px solid cl(border);
    border-radius: 0 br(lg) br(lg) br(lg);
  }

  &_position {
    &_left {
      .v-tabs-list__group-content {
        justify-content: flex-start;
      }
    }

    &_center {
      .v-tabs__container {
        border-radius: br(lg) br(lg) br(lg) br(lg);
      }

      .v-tabs-list {
        justify-content: center;
      }
    }

    &_right {
      .v-tabs__container {
        border-radius: br(lg) 0 br(lg) br(lg);
      }

      .v-tabs-list__group {
        justify-content: flex-end;
      }
    }
  }

  &_size {
    &_lg {
      .v-tabs-list__item {
        padding: 1.25rem 1.75rem;
      }
    }
  }

  &_type {
    &_paginate {
      .v-tabs {
        &__container {
          border-radius: 0 0 br(lg) br(lg);
        }
      }

      .v-tabs-list {
        background-color: white;
        padding: 0;
        margin-top: 0;
        border-radius: br(lg) br(lg) 0 0;

        &__item {
          margin: 0;
          box-shadow: none;
          transition: background-color .4s ease;

          &:not(&_is_active) {
            color: rgba(cl(rgb-dark), .7);
          }

          &:hover {
            background-color: rgba(cl(rgb-primary), .05);
          }

          &:first-child {
            border-radius: 0;
            border-left: none;
          }

          &:last-child {
            border-radius: 0;
            border-right: none;
          }
        }

        &__button {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 0 0 auto;
          margin: 0;
          padding: 0;
          width: 2.5rem;
          border: 1px solid cl(border);
          background-color: white;
          transition: background-color .3s;
          cursor: default;
          user-select: none;
          pointer-events: none;

          &:first-child {
            border-bottom-right-radius: br(lg);
          }

          &:last-child {
            border-top-right-radius: br(lg);
          }

          .v-icon {
            opacity: .5;
          }
        }
      }

      &.v-tabs_paginate {
        &_prev-btn {
          .v-tabs-list__button {
            &:first-child {
              cursor: pointer;
              pointer-events: auto;

              &:hover {
                background-color: rgba(cl(rgb-primary), .1);
              }

              .v-icon {
                opacity: 1
              }
            }
          }
        }

        &_next-btn {
          .v-tabs-list__button {
            &:last-child {
              cursor: pointer;
              pointer-events: auto;

              &:hover {
                background-color: rgba(cl(rgb-primary), .1);
              }

              .v-icon {
                opacity: 1
              }
            }
          }
        }
      }
    }

    &_outlined {
      box-shadow: none;

      .v-tabs {
        &__container {
          box-shadow: none;
        }
      }

      .v-tabs-list {
        &__item {
          box-shadow: none;
          border: 1px solid cl(border);

          &_is {
            &_active {
              border-bottom-color: white;
            }
          }
        }
      }
    }
  }
}

.v-tabs-list {
  position: relative;
  z-index: 1;
  bottom: -1px;
  overflow: hidden;
  display: flex;
  width: 100%;
  flex-wrap: nowrap;

  &__group {
    display: flex;
    flex: 1 1 auto;
    overflow: hidden;
    cursor: auto;
  }

  &__group-content {
    display: flex;
    flex: 0 0 auto;
    position: relative;
    transition: .3s cubic-bezier(.25, .8, .5, 1);
    white-space: nowrap;
  }

  // кнопки в нормальном режиме
  &__item {
    overflow: hidden;
    width: auto;
    position: relative;
    z-index: 1;
    padding: .75rem 1rem;
    font-family: var(--font-primary);
    font-weight: 600;
    color: var(--color-text-primary);
    box-shadow: none;
    border: 1px solid cl(border);
    background-color: white;
    white-space: nowrap;
    text-overflow: ellipsis;
    user-select: none;
    cursor: pointer;
    transition: color .3s ease;

    &:not(:last-child) {
      border-right: none;
    }

    &:first-child {
      border-top-left-radius: br(lg);
    }

    &:last-child {
      border-top-right-radius: br(lg);
    }

    svg {
      fill: var(--color-text-primary);
      transition: fill .3s ease;
    }

    &:hover {
      color: var(--color-primary);

      svg {
        fill: var(--color-primary);
      }
    }

    &_is {
      &_active {
        background-color: white;
        color: var(--color-primary);
        border-bottom-color: white;

        svg {
          fill: var(--color-primary);
        }
      }
    }
  }

  // кнопки для карусели
  &__button {
    display: none;
  }
}
</style>
