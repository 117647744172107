const defaultState = {
  isLoading: false
}

export default {
  namespaced: true,

  state: { ...defaultState },

  mutations: {
    SET_LOADING (state, loadingState) {
      state.isLoading = loadingState
    }
  },

  actions: {
    startLoading ({ commit, state }) {
      if (state.isLoading) return
      commit('SET_LOADING', true)
    },
    stopLoading ({ commit, state }) {
      if (!state.isLoading) return
      commit('SET_LOADING', false)
    }
  }
}
