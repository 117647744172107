const handleGesture = (wrapper) => {
  const { dragstartX, dragendX, dragstartY, dragendY } = wrapper
  const dirRatio = 0.5
  const minDistance = 16
  wrapper.offsetX = dragendX - dragstartX
  wrapper.offsetY = dragendY - dragstartY

  if (Math.abs(wrapper.offsetY) < dirRatio * Math.abs(wrapper.offsetX)) {
    wrapper.left && (dragendX < dragstartX - minDistance) && wrapper.left(wrapper)
    wrapper.right && (dragendX > dragstartX + minDistance) && wrapper.right(wrapper)
  }

  if (Math.abs(wrapper.offsetX) < dirRatio * Math.abs(wrapper.offsetY)) {
    wrapper.up && (dragendY < dragstartY - minDistance) && wrapper.up(wrapper)
    wrapper.down && (dragendY > dragstartY + minDistance) && wrapper.down(wrapper)
  }
}

function dragstart (event, wrapper) {
  wrapper.dragstartX = event.clientX
  wrapper.dragstartY = event.clientY
  wrapper.start && wrapper.start(event, wrapper)
}

function dragend (event, wrapper) {
  wrapper.dragendX = event.clientX
  wrapper.dragendY = event.clientY
  handleGesture(wrapper)
  wrapper.end && wrapper.end(event, wrapper)
}

function drag (event, wrapper) {
  wrapper.dragX = event.clientX
  wrapper.dragY = event.clientY
  wrapper.move && wrapper.move(event, wrapper)
}

function createHandlers (value) {
  const wrapper = {
    dragstartX: 0,
    dragstartY: 0,
    dragendX: 0,
    dragendY: 0,
    dragX: 0,
    dragY: 0,
    offsetX: 0,
    offsetY: 0,
    left: value.left,
    right: value.right,
    up: value.up,
    down: value.down,
    start: value.start,
    move: value.move,
    end: value.end
  }

  return {
    dragstart: (e) => dragstart(e, wrapper),
    dragend: (e) => dragend(e, wrapper),
    drag: (e) => drag(e, wrapper)
  }
}

function inserted (el, binding, vnode) {
  const value = binding.value
  const target = value.parent ? el.parentElement : el
  const options = value.options || { passive: true }

  // Needed to pass unit tests
  if (!target) return

  target.setAttribute('draggable', 'true')

  const handlers = createHandlers(binding.value)
  target._dragHandlers = Object(target._dragHandlers)
  target._dragHandlers[vnode.context._uid] = handlers

  Object.keys(handlers).forEach(eventName => {
    target.addEventListener(eventName, handlers[eventName], options)
  })
}

function unbind (el, binding, vnode) {
  const target = binding.value.parent
    ? el.parentElement
    : el

  if (!target || !target._dragHandlers) return

  const handlers = target._dragHandlers[vnode.context._uid]

  Object.keys(handlers).forEach(eventName => {
    target.removeEventListener(eventName, handlers[eventName])
  })

  delete target._dragHandlers[vnode.context._uid]
}

export const Drag = {
  inserted,
  unbind
}

export default Drag
