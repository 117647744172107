<template>
  <div
      v-ripple="computedRipple"
      :class="classes"
      class="v-card-button"
      @click="onClick"
  >
    <div
        v-if="withSelector"
        class="v-card-button__selector"
    >
      <slot
          name="selector"
          :events="{ toggleActive }"
      />
    </div>

    <div class="v-card-button__content">
      <slot
          name="default"
          :events="{ toggleActive }"
      />
    </div>
  </div>
</template>

<script>
import Ripple from '@/directives/ripple'

// todo #depricated вместо него использовать v-sheet
export default {
  name: 'VCardButton',

  directives: {
    Ripple
  },

  props: {
    active: Boolean,
    bgSolid: Boolean,
    disabled: Boolean,
    error: Boolean,
    noGutter: Boolean,
    ripple: {
      type: [Boolean, Object],
      default: () => ({ class: 'text-color-primary' })
    },
    static: Boolean
  },

  data () {
    return {
      isActive: false
    }
  },

  computed: {
    classes () {
      return {
        'v-card-button_is_active': (this.isActive || this.active) && !this.disabled,
        'v-card-button_is_error': this.error,
        'v-card-button_is_disabled': this.disabled,
        'v-card-button_is_static': this.static,
        'v-card-button_is_no-gutter': this.noGutter,
        'v-card-button_with_selector': this.withSelector,
        'v-card-button_type_bg-solid': this.bgSolid
      }
    },
    clickable () {
      return !this.disabled && !this.static
    },
    computedRipple () {
      return this.clickable
        ? this.ripple
        : false
    },
    withSelector () {
      return this.$scopedSlots.selector
    }
  },

  methods: {
    onClick (e) {
      if (this.disabled) return
      this.$emit('click', e)
    },
    toggleActive (value, isActive) {
      this.isActive = isActive
    }
  }
}
</script>

<style lang="scss">
@import "~@styles/variables";
@import "~@styles/tools";

.v-card-button {
  position: relative;
  background-color: transparent;
  border: 1px solid cl(border);
  border-radius: map-get($rounded, null);
  cursor: pointer;
  transition: border-color .3s ease;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: inherit;
    background-color: cl(primary);
    opacity: 0;
    transition: opacity .3s ease;
  }

  &:hover,
  &_is_active {
    border-color: clha($primary, .5);

    > .v-card-button__selector {
      border-right: 1px solid clha($primary, .5);
    }
  }

  &__selector {
    display: flex;
    align-items: center;
    padding: .5rem;
    border-right: 1px solid cl(border);
  }

  &__content {
    align-self: center;
    padding: .5rem;
  }

  &_with {
    &_selector {
      height: 100%; // IE11
      display: flex;
    }
  }

  &_is {
    &_active {
      &::before {
        opacity: .15;
      }
    }

    &_static,
    &_disabled {
      cursor: default;

      &:hover {
        border-color: cl(border);

        > .v-card-button__selector {
          border-right: 1px solid cl(border);
        }
      }
    }

    &_disabled {
      opacity: .5;
      user-select: none;

      &.v-card-button_is_active {
        border-color: cl(border);

        > .v-card-button__selector {
          border-right: 1px solid cl(border);
        }
      }
    }

    &_no-gutter {
      .v-card-button__selector,
      .v-card-button__content {
        padding: 0;
      }
    }

    &_error {
      border-color: cl(danger);

      > .v-card-button__selector {
        border-right: 1px solid cl(danger);
      }
    }
  }

  &_type {
    &_bg-solid {
      transition: background-color .3s ease;

      &::before {
        display: none;
      }

      &.v-card-button_is_active {
        background-color: clha($primary, 0.1);
      }
    }
  }
}
</style>
