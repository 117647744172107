import App from '@/App.vue'
import Bowser from 'bowser'
import Vue from 'vue'
import cssVars from 'css-vars-ponyfill'
import router from '@/router'
import svg4everybody from 'svg4everybody'
import { Modeable, Themeable } from '@mixins'
import { addScriptToHead } from '@utils/helpers'
import { store } from '@/store'
import { i18n } from '@/i18n'

const bowser = Bowser.getParser(window.navigator.userAgent)
const {
  NODE_ENV,
  VUE_APP_GUIDE_PATH,
  VUE_APP_AUTH_PATH
} = process.env

// глобальные опции vue
const data = {
  isDev: NODE_ENV === 'development',
  VUE_APP_GUIDE_PATH,
  VUE_APP_AUTH_PATH,
  error: {
    message: i18n.t('site.message.goToTestLink')
  }
}

// для IE11
if (bowser.isBrowser('Internet Explorer')) {
  // Полифилы
  addScriptToHead('https://polyfill.io/v3/polyfill.min.js?features=Array.prototype.%40%40iterator,Array.prototype.find,Promise,Object.assign,ResizeObserver', true)
  cssVars({ watch: true }) // var() css
  svg4everybody()
  document.body.classList.add('ie') // Метка для css
}

Vue.config.productionTip = false

const instance = new Vue({
  i18n,
  data,
  store,
  router,
  mixins: [
    Themeable,
    Modeable
  ],
  render: h => h(App)
}).$mount('#app')

export default instance
