<template>
  <v-menu>
    <template #button>
      <v-star
        :color="color"
        :size="size"
        :value="!!value"
        outlined
      />
    </template>

    <div
      :class="[sizeClasses]"
      class="star-line-drop"
    >
      <star-line
        :color="color"
        :size="size"
        :value="value"
        :variant="variant"
        @input="onInput"
      />
    </div>
  </v-menu>
</template>

<script>
import { VMenu, VStar } from '@components/base'
import { StarLine } from '@components/features'
import { Sizeable } from '@/mixins'

export default {
  name: 'StarLineDrop',

  components: {
    StarLine,
    VMenu,
    VStar
  },

  mixins: [Sizeable],

  props: {
    active: Boolean,
    value: [String, Number],
    variant: Array,
    size: String,
    color: String
  },

  methods: {
    onInput (newValue) {
      this.$emit('input', newValue)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@styles/variables";
@import "~@styles/tools";

.star-line-drop {
  margin: .5rem;
  width: auto;
  min-width: 7rem;
  max-width: 12rem;

  &_size {
    @each $el-size in map-keys($spacers) {
      $el-local-size: map-get($spacers, $el-size);

      &_#{$el-size} {
        min-width: $el-local-size * 3 + 1rem;
        max-width: $el-local-size * 5 + 1rem;
      }
    }
  }
}
</style>
