<template>
  <v-table
    :striped="!unique"
  >
    <template #thead>
      <v-table-tr>
        <v-table-th class="v-table-th_is_first">
          <slot name="th-first"></slot>
        </v-table-th>

        <v-table-th
          ref="thAnswers"
          v-for="(tableTitle, headerColIndex) in variantCol"
          :key="tableTitle.id"
          :style="{
            backgroundColor: getThColor(headerColIndex),
            minWidth: `${thAnswersWidth}px`
          }"
        >
          {{ tableTitle.text }}
        </v-table-th>
      </v-table-tr>
    </template>

    <template #default>
      <template
        v-for="(row, i) in variantMap"
      >
        <v-table-tr
          :key="i"
          :active="row !== null"
          :error="row === null && showWarnings"
        >
          <v-table-td>
            {{ variantRow[i].text }}
          </v-table-td>

          <v-table-td
            v-for="(col, j) in variantCol"
            v-ripple="ripple"
            :key="j"
            :active="unique && variantMap.includes(j)"
            class="p-0"
          >
            <v-radio
              :value="j"
              :model-value="variantMap[i]"
              full-height
              @change="onRadioChange($event, i)"
            ></v-radio>
          </v-table-td>
        </v-table-tr>

        <v-table-tr
          v-if="variantMap[i] !== null"
          :key="`textarea${i}`"
          active
        >
          <v-table-td
            :colspan="variantCol.length + 1"
          >
            <v-field>
              <v-textarea
                v-model="variantRow[i].comment"
                autogrow
              ></v-textarea>
            </v-field>
          </v-table-td>
        </v-table-tr>
      </template>

    </template>
  </v-table>
</template>

<script>
import { Ripple } from '@directives'
import { convertToColorSpectrum } from '@utils/helpers'
import {
  VField,
  VRadio,
  VTable,
  VTableTr,
  VTableTh,
  VTableTd,
  VTextarea
} from '@components/base'

export default {
  name: 'TableRadio',

  directives: {
    Ripple
  },

  components: {
    VTextarea,
    VField,
    VRadio,
    VTableTh,
    VTableTd,
    VTableTr,
    VTable
  },

  props: {
    unique: Boolean,
    variantRow: Array,
    variantCol: Array,
    showWarnings: Boolean,
    ripple: {
      type: [Boolean, Object],
      default: () => ({ class: 'text-color-primary' })
    }
  },

  data () {
    return {
      variantMap: [],
      // в каком виде отдавать данные
      returnedDataPattern: (rowId, colId) => `${rowId};${colId}`,
      thAnswersWidth: 0
    }
  },

  computed: {
    variantMapAnswerLength () {
      return this.variantMap.filter(variant => variant !== null && variant !== -1).length
    }
  },

  mounted () {
    this.variantRow.forEach((value, index) => {
      this.variantMap.splice(index, 1, null)
    })

    this.$nextTick(() => {
      this.$refs.thAnswers.forEach(el => {
        if (el.$el.clientWidth > this.thAnswersWidth) {
          this.thAnswersWidth = el.$el.clientWidth
        }
      })
    })
  },

  methods: {
    /**
     * Обработчик смены радиокнопок
     * @param {string | number} value - значение кнопки
     * @param {number} valueIndex - ряд, в котором находится кнопка
     */
    onRadioChange (value, valueIndex) {
      if (this.unique) {
        const oldIndex = this.variantMap.indexOf(value)
        const currVal = this.variantMap[valueIndex]

        // замена старого значения на уже имеющееся
        if (oldIndex > -1) {
          this.variantMap.splice(oldIndex, 1, currVal)
        }
      }

      this.variantMap.splice(valueIndex, 1, value)

      // последовательность важна!
      if (this.unique) {
        // дозаполнение колонок если вариант остался только один
        const condition = this.variantMapAnswerLength === this.variantCol.length - 1 &&
          this.variantCol.length === this.variantRow.length

        if (condition) {
          const nullRowIndex = this.variantMap.indexOf(null)
          // fixme возможно конструкцию можно упростить
          const notUsedValue = this.variantCol
            .map((val, i) => i)
            .find(value => this.variantMap.indexOf(value) === -1)

          this.variantMap.splice(nullRowIndex, 1, +notUsedValue)
        }

        // последовательность важна!
        // подкрашивание всех колонок при выборе всех возможных вариантов
        if (this.variantMapAnswerLength === this.variantCol.length) {
          this.variantMap = this.variantMap.map(variant => {
            return variant === null ? -1 : variant
          })
        }
      }

      this.$emit('change', this.getData())
    },

    /**
     * Подсчитывает финальный результат
     *
     * @example
     * В родителе можно сделать так
     *
     * onClick () {
     *  console.log(this.$refs.radioTable.getData())
     * }
     */
    getData () {
      const answer = []

      this.variantMap.forEach((col, i) => {
        if (this.variantCol[col]) {
          answer.push(this.returnedDataPattern(this.variantRow[i].id, this.variantCol[col].id))
        }

        if (col === -1) {
          answer.push(this.returnedDataPattern(this.variantRow[i].id, ''))
        }
      })

      return answer
    },

    /**
     * Отдаёт текущий цвет из диапазона
     * @param  {number} currIndex - индекс текущей ячейки
     * @returns {string|null} - цвет для ячейки
     */
    getThColor (currIndex) {
      const lastIndex = this.variantCol.length - 1

      // если таблица с неуникальным выбором, последняя ячейка без цвета
      // if (!this.unique && currIndex === lastIndex) return null

      return convertToColorSpectrum({
        rgb: '223, 230, 235',
        currIndex,
        minOpacity: 0.3,
        maxIndex: lastIndex
        // maxIndex: this.unique ? lastIndex : lastIndex - 1
      })
    }
  }
}
</script>
