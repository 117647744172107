<template>
  <div
    :class="classes"
    class="video-player"
  >
    <video ref="player"/>
  </div>
</template>

<script>
import Plyr from 'plyr/dist/plyr.polyfilled.js'
import { Roundable } from '@mixins'

export default {
  name: 'VideoPlayer',

  mixins: [
    Roundable
  ],

  props: {
    options: {
      type: Plyr.Options, // https://github.com/sampotts/plyr#options
      default () {
        return {
          controls: ['play-large', 'play', 'progress', 'current-time', 'pip', 'fullscreen']
        }
      }
    },
    source: Plyr.source // https://github.com/sampotts/plyr#the-source-setter
  },

  data () {
    return {
      player: {
        el: null
      }
    }
  },

  computed: {
    classes () {
      return {
        ...this.roundedClasses
      }
    }
  },

  mounted () {
    this.initPlayer()
  },

  methods: {
    initPlayer () {
      this.player.el = new Plyr(this.$refs.player, this.options)
      this.player.el.source = this.source
    }
  }
}
</script>

<style lang="scss">
@use "sass:map";
@import "~plyr/src/sass/plyr.scss";
@import "~@styles/variables";
@import "~@styles/tools";

:root {
  --plyr-color-main: #{cl(primary)};
}

.video-player {
  overflow: hidden;
}
</style>
