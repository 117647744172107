<template>
    <v-sheet
        rounded="lg"
        elevation="8"
        class="l-modals-base"
    >
        <slot name="header"/>
        <slot/>
        <slot name="footer"/>
    </v-sheet>
</template>

<script>
import VSheet from '@/components/base/VSheet'

export default {
  name: 'LModalBase',

  components: {
    VSheet
  }
}
</script>

<style lang="scss">
@import "~@assets/styles/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.l-modals-base {
  position: relative;
  z-index: 2;
  width: 100%;
  height: auto;
  max-height: 100%;

  @include media-breakpoint-up(md) {
    max-width: 70%;
  }

  @include media-breakpoint-up(xl) {
    max-width: 50%;
  }
}
</style>
