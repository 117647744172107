<template>
  <section class="drag-ranging">
    <div
      v-for="(list, listIndex) in lists"
      :key="listIndex"
      class='drag-ranging__item'
    >
      <draggable
        ref="ul"
        tag="ul"
        :list="list"
        :force-fallback="true"
        :style="{
          minHeight: `${minHeight}px`
        }"
        group="questions"
        animation="200"
        class='drag-ranging__list'
        @change="changeAnswer($event, listIndex)"
      >
        <li
          v-for='(item, itemIndex) in list'
          :key='item.code'
          :class="itemClasses"
          class='drag-ranging-item'
        >
          <div class="drag-ranging-item__text">{{ item.text }}</div>

          <div
            :class="{'drag-ranging-item__btn-group_hovered': listIndex === 0}"
            class="drag-ranging-item__btn-group"
          >
            <button
              v-if="listIndex === 0"
              @click="moveRight(itemIndex, item)"
              type="button"
              class="drag-ranging-item__button"
            >
              <v-icon-svg
                fill="none"
                stroke="currentColor"
                view-box="0 0 10 18"
                title="svg-icon-chevron"
                width="1rem"
                height="1rem"
              >
                <svg-icon-chevron2 />
              </v-icon-svg>
            </button>

            <template v-else>
              <button
                v-if="itemIndex !== 0"
                @click="swapItems(itemIndex, -1 )"
                type="button"
                class="drag-ranging-item__button"
              >
                <v-icon-svg
                  class="rotate-270"
                  fill="none"
                  stroke="currentColor"
                  view-box="0 0 10 18"
                  name="Переместить вверх"
                  width="1rem"
                  height="1rem"
                >
                  <svg-icon-chevron2 />
                </v-icon-svg>
              </button>

              <button
                v-if="itemIndex !== lists[listIndex].length - 1"
                @click="swapItems(itemIndex)"
                type="button"
                class="drag-ranging-item__button"
              >
                <v-icon-svg
                  class="rotate-90"
                  fill="none"
                  stroke="currentColor"
                  view-box="0 0 10 18"
                  name="Переместить вниз"
                  width="1rem"
                  height="1rem"
                >
                  <svg-icon-chevron2 />
                </v-icon-svg>
              </button>
            </template>
          </div>
        </li>
      </draggable>

      <div
        v-if="!list.length && listIndex"
        :style="{
          minHeight: `${minHeight}px`
        }"
        class='dr-empty'
      >
        <div class="dr-empty__block">
          <div class="dr-empty__icon">
            <v-icon
              fill="primary"
              name="plus"
              size="16"
            />
          </div>

          <div class="dr-empty__text">
            {{ $t('processing.input.dragRanging.emptyListMessage') }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import draggable from 'vuedraggable'
import VIconSvg from '@components/base/VIconSvg/VIconSvg'
import VIcon from '@components/base/VIcon/VIcon.vue'
import SvgIconChevron2 from '@components/icons/SvgIconChevron2'

export default {
  name: 'DragRanging',
  components: {
    draggable,
    SvgIconChevron2,
    VIcon,
    VIconSvg
  },
  props: {
    variants: Array,
    error: Boolean,
    values: {
      type: Array,
      defaults: []
    }
  },

  data () {
    return {
      minHeight: 0,
      drag: false
    }
  },

  computed: {
    itemClasses () {
      return {
        'drag-ranging-item_is_error': this.error
      }
    },
    lists () {
      if (!this.values.length) return [[...this.variants], []]

      return this.values.reduce((result, code) => {
        const variantIndex = result[0].findIndex(variant => variant.code === code)
        const [answerElement] = result[0].splice(variantIndex, 1)

        result[1] = [...result[1], answerElement]

        return result
      }, [[...this.variants], []])
    }
  },

  watch: {
    variants () {
      this.recalculateSize()
    }
  },

  mounted () {
    this.recalculateSize()
  },

  methods: {
    /**
     * Перемещает элемент в правую колонку (кнопка)
     * @param {object} event - объект события change библиотеки draggable
     * @param {number} position - индекс колонки
     */
    changeAnswer (event, position) {
      this.$emit('change', JSON.parse(JSON.stringify(this.lists[1])))
    },
    /**
     * Перемещает элемент в правую колонку (кнопка)
     * @param {number} itemIndex - индекс ячейки из которой происходит перемещение
     * @param {number} item - перемещаемы элемент
     */
    moveRight (itemIndex, item) {
      const newAnswers = [...this.lists[1]]

      newAnswers.push(item)
      this.change(newAnswers)
    },
    /**
     * Меняет местами два элемента массива (кнопка)
     * @param {number} itemIndex - индекс ячейки
     * @param {number} direction - направление (1 - вниз, -1 - вверх)
     */
    swapItems (fromIndex, direction = 1) {
      const newAnswers = [...this.lists[1]]
      const newIndex = fromIndex + direction
      const [element] = newAnswers.splice(fromIndex, 1)

      newAnswers.splice(newIndex, 0, element)

      this.change(newAnswers)
    },
    /** Выравнивание по высоте **/
    recalculateSize () {
      this.$nextTick(() => {
        this.$refs.ul.forEach(ul => {
          if (ul.$el.offsetHeight > this.minHeight) {
            this.minHeight = ul.$el.offsetHeight
          }
        })
      })
    },
    change (newAnswers) {
      this.$emit('change', newAnswers)
    }
  }
}
</script>

<style
  lang="scss"
  scoped
>
@import "~@assets/styles/variables";
@import "~bootstrap/scss/mixins/breakpoints";

// скрытие кнопок при преретаскивании
.sortable-fallback,
.sortable-ghost {
  .drag-ranging-item__btn-group {
    display: none !important;
  }
}

.sortable-fallback {
  &::before {
    display: none !important;
  }
}

.dr-empty {
  position: absolute;
  top: 0;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(var(--v-sheet-color-rgb-primary), 0.5);
  padding: 1rem;

  &__block {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
  }

  &__icon {
    padding-right: .5rem;
  }
}

.drag-ranging {
  display: flex;
  justify-content: space-between;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }

  &__item {
    position: relative;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;

    &:first-child {
      .drag-ranging__list {
        @include media-breakpoint-down(sm) {
          min-height: 4rem !important;
        }
      }

      .drag-ranging-item {
        &_is_error {
          .drag-ranging-item__text {
            border-color: var(--color-danger);
          }
        }
      }
    }

    &:not(:first-child) {
      margin-left: 2.5rem;

      @include media-breakpoint-down(sm) {
        margin-left: 0;
        margin-top: 2rem;
      }

      .drag-ranging__list {
        counter-reset: list;
        border-radius: 4px;
        border: 1px dashed rgba(var(--v-sheet-color-rgb-primary), 0.5);

        @include media-breakpoint-down(sm) {
          margin-left: 1.5rem;
        }
      }

      .drag-ranging-item {
        position: relative;

        &:before {
          content: counter(list);
          counter-increment: list;
          position: absolute;
          top: 0;
          width: 2rem;
          left: -2.6rem;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding: .5rem 0;
          text-align: right;
        }

        &__text {
          @include media-breakpoint-up(md) {
            background-color: rgba(var(--v-sheet-color-rgb-primary), 0.1);
            border-color: rgba(var(--v-sheet-color-rgb-primary), 0.5);
          }
        }

        &__btn-group {
          border: 1px solid cl(primary);
        }
      }
    }
  }

  &__list {
    position: relative;
    list-style: none;
    padding: .25rem;
    margin: 0;
  }

  &__title {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: .5rem;
  }
}

.drag-ranging-item {
  display: flex;
  user-select: none;

  @include media-breakpoint-down(sm) {
    position: relative;
    padding-right: 3rem;
    border-radius: 4px;
    border: 1px solid cl(border);
    cursor: grab;

    &::after {
      content: "";
      position: absolute;
      display: block;
      right: .75rem;
      top: 50%;
      width: 1rem;
      height: 1rem;
      transform: translate(0, -50%);
      background: url("~@/assets/images/icons/drag.svg") center no-repeat;
      pointer-events: none;
    }
  }

  &:not(:last-child) {
    margin-bottom: .5rem;
  }

  &.sortable-ghost {
    opacity: .5;
  }

  &__text {
    padding: .5rem;
    flex-grow: 1;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid cl(border);
    cursor: grab;

    @include media-breakpoint-down(sm) {
      border: none;
    }
  }

  &__btn-group {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    min-width: 2.5rem;
    margin-left: .25rem;
    padding: 0;
    border-radius: 4px;

    @include media-breakpoint-down(sm) {
      display: none;
    }

    &:empty {
      display: none;
    }

    &_hovered {
      border-color: transparent;
    }
  }

  &__button {
    width: 100%;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    margin: 0;
    color: cl(primary);
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    user-select: none;
    transition: color .3s ease, background-color .3s ease;

    > svg {
      margin-bottom: -3px;
    }

    &:hover {
      background-color: cl(primary);
      color: white;
    }
  }
}
</style>
