<template>
    <div
      v-show="VTabs.activeTabId === id"
      :key="id"
      class="v-tab"
    >
      <template v-if="VTabs.items[id].isVisited">
        <slot/>
      </template>
    </div>
</template>

<script>
import { getRandomUuid } from '@utils/helpers'

export default {
  name: 'VTab',

  inject: ['VTabs'],

  props: {
    id: {
      type: [String, Number],
      default: () => 'v-tab-' + getRandomUuid()
    },
    label: [String, Number]
  },

  created () {
    this.setTabData()
  },

  beforeDestroy () {
    this.$delete(this.VTabs.items, this.id)
  },

  methods: {
    setTabData () {
      this.$set(this.VTabs.items, this.id, {
        id: this.id,
        label: this.label,
        isVisited: false
      })
    }
  }
}
</script>
