<template>
  <label
    :class="classes"
    class="v-checkbox"
  >
    <input
      :id="id"
      :name="name"
      :value="value"
      :checked="shouldBeChecked"
      :disabled="disabled"
      @change="onChange"
      @focus="onFocus"
      @blur="onBlur"
      type="checkbox"
      class="v-checkbox__input"
    >
    <span class="v-checkbox__bullet"></span>
    <span
      v-if="$slots.default"
      class="v-checkbox__slot"
    >
      <slot></slot>
    </span>
  </label>
</template>

<script>
/**
 * v-checkbox
 *
 * Работа с чекбоксом происходит через дерективу v-model
 * При необходимости можно разделить на :model-value и @change
 *
 * Если входной параметр Boolean,
 * чекбокс будет работать как переключатель - true, false
 * которые установлены по умолчанию, но их можно поменять
 * через входные параметры true-value и false-value соответственно
 *
 * Если входной параметр Array, чекбокс будет добавлять, или удалять
 * значения, объявленные в value всех завязанных на массив чекбоксов
 *
 * @example
 * Клик на первый чекбокс cbArr = ["1"]
 * Затем клик на второй чекбокс cbArr = ["1", "2"]
 * {
 *  template: `
 *   <div>
 *     <v-checkbox value="1" v-model="cbArr">1</v-checkbox>
 *     <v-checkbox value="2" v-model="cbArr">2</v-checkbox>
 *   </div>
 * `,
 *   data () {
 *     return {
 *       cbArr: [],
 *     }
 *   }
 * }
 */
export default {
  name: 'v-checkbox',

  model: {
    prop: 'modelValue',
    event: 'change'
  },

  props: {
    value: [String, Number],
    modelValue: {
      type: [Boolean, Array],
      default: false
    },
    error: Boolean,
    success: Boolean,
    disabled: Boolean,
    id: String,
    name: String,
    trueValue: {
      default: true
    },
    falseValue: {
      default: false
    }
  },

  computed: {
    classes () {
      return {
        'v-checkbox_is_error': this.error,
        'v-checkbox_is_success': this.success,
        'v-checkbox_is_disabled': this.disabled
      }
    },

    shouldBeChecked () {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value)
      }

      return this.modelValue === this.trueValue
    }
  },

  methods: {
    onChange (event) {
      const isActive = event.target.checked

      if (this.modelValue instanceof Array) {
        const oldValue = [...this.modelValue]
        const newValue = isActive
          ? [...this.modelValue, this.value]
          : this.modelValue.filter(value => value !== this.value)

        this.$emit('change', newValue, oldValue, this.value)
      } else {
        const newValue = isActive ? this.trueValue : this.falseValue
        const oldValue = isActive ? this.falseValue : this.trueValue

        this.$emit('change', newValue, oldValue, this.value)
      }
    },
    onFocus (e) {
      this.isFocused = true
      this.$emit('focus', e)
    },
    onBlur (e) {
      this.isFocused = false
      this.$emit('blur', e)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@styles/variables/index.scss";
@import "~@styles/tools/index.scss";

$checkbox-opacity-transition: opacity .25s map-get($transition, "ease-in-out");
$checkbox-bg-transition: background-color .25s map-get($transition, "ease-in-out");

.v-checkbox {
  position: relative;
  display: inline-flex;
  width: 100%;
  min-width: 1.4rem; // IE11
  min-height: 1.4rem; // IE11
  outline: none;
  cursor: pointer;

  &__input {
    position: absolute;
    opacity: 0;
    width: 1rem;
    height: 1rem;
    margin: .2rem;
    padding: 0;
    border: none;
    user-select: none;

    &:checked + .v-checkbox__bullet {
      border-color: cl(primary);
      background-color: cl(primary);

      &::after {
        opacity: 1;
      }
    }
  }

  &__bullet {
    position: relative;
    display: inline-flex;
    flex: 0 0 auto;
    width: 1rem;
    height: 1rem;
    margin: .2rem;
    color: inherit;
    border-radius: 3px;
    user-select: none;
    border: 1px solid cl(primary);
    transition: $checkbox-bg-transition;

    &:hover {
      &::before {
        opacity: .1;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: -.75rem;
      right: -.75rem;
      bottom: -.75rem;
      left: -.75rem;
      border-radius: 100%;
      background-color: cl(primary);
      opacity: 0;
      transition: $checkbox-opacity-transition;
    }

    &::after {
      content: "";
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      width: .5rem;
      height: .5rem;
      border-radius: 3px;
      background: url("~@/assets/images/icons/checkbox-checked.svg") center no-repeat;
      transform: translate(-50%, -50%);
      transition: $checkbox-opacity-transition;
    }
  }

  &__slot {
    display: inline-flex;
    margin-left: .5rem;
    user-select: none;
  }

  &_is {
    &_success {
      .v-checkbox__bullet {
        border-color: cl(success);

        &::before {
          background-color: cl(success);
        }
      }

      .v-checkbox__input {
        &:checked + .v-checkbox__bullet {
          border-color: cl(success);
          background-color: cl(success);
        }
      }
    }

    &_error {
      .v-checkbox__bullet {
        border-color: cl(danger);

        &::before {
          background-color: cl(danger);
        }
      }

      .v-checkbox__input {
        &:checked + .v-checkbox__bullet {
          border-color: cl(danger);
          background-color: cl(danger);
        }
      }
    }

    &_disabled {
      cursor: default;

      .v-checkbox__bullet {
        border-color: cl(disabled);

        &:hover::before {
          opacity: 0;
        }
      }
    }
  }
}
</style>
