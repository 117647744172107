<template>
  <div class="lch-360">
    <div class="lch-360__container">
      <v-sheet
        :value="isActive"
        :ripple="null"
        outlined
        rounded
        class="h-100 p-8"
      >
        <div class="lch-360-linear">
          <div class="lch-360-linear__left">
            <slot name="textLeft"></slot>
          </div>

          <div class="lch-360-linear__container">
            <slot>
              <v-linear-radio
                :bg-color="isActive ? 'var(--color-background-2)' : '#FFFFFF'"
                size="24"
                type360
                :value="value"
                :variants="variants"
                @input="setValue"
              />
            </slot>
          </div>

          <div class="lch-360-linear__right">
            <slot name="textRight"></slot>
          </div>
        </div>

      </v-sheet>
    </div>

    <div
      v-if="noVariant"
      class="lch-360__no-value"
    >
      <div class="lch-360-btn">
        <v-sheet
          :value="value === noVariant.code"
          :ripple="null"
          outlined
          rounded
          @click="setValue(noVariant.code)"
          class="lch-360-btn__card"
        >
          <div class="lch-360-btn__text">
            {{ noVariant.text }}
          </div>

          <div class="lch-360-btn__input">
            <v-radio
              :model-value="value"
              size="24"
              :value="noVariant.code"
              @change="setValue"
              class="lch-360-btn__radio"
            />
          </div>
        </v-sheet>

        <v-button
          :active="value === noVariant.code"
          outlined
          size="sm"
          @click="setValue(noVariant.code)"
          class="lch-360-btn__button"
        >
          {{ noVariant.text }}`
        </v-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  VButton,
  VLinearRadio,
  VRadio,
  VSheet
} from '@components/base'

export default {
  name: 'LinearChoice360',

  components: {
    VSheet,
    VButton,
    VLinearRadio,
    VRadio
  },

  props: {
    value: [String, Number],
    variants: Array,
    noVariant: Object
  },

  computed: {
    isActive () {
      return !!this.value
    }
  },

  methods: {
    /**
     * Устанавливат новове значение
     * @param {string | number} newValue - выбранное значение
     */
    setValue (newValue) {
      this.$emit('input', newValue)
    }
  }
}
</script>

<style lang="scss">
@import "~@assets/styles/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.lch-360 {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;

  @include media-breakpoint-down(lg) {
    justify-content: flex-end;
  }

  &__container {
    position: relative;
    flex-basis: 0;
    flex-grow: 1;
    width: 100%;
    max-width: 100%;
    padding-right: 0.5rem;
    padding-left: 0.5rem;

    @include media-breakpoint-down(lg) {
      flex-basis: 100%;
    }
  }

  &__no-value {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}

.lch-360-linear {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include media-breakpoint-down(lg) {
    align-items: flex-start;
    flex-wrap: wrap;
  }

  &__left {
    width: 30%;
    padding-right: 2rem;

    @include media-breakpoint-down(lg) {
      width: 50%;
      order: 1;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      padding: 0 0 1rem 0;
    }
  }

  &__container {
    width: 40%;

    @include media-breakpoint-down(lg) {
      order: 3;
      width: 100%;
      margin-top: 2rem;
    }

    @include media-breakpoint-down(sm) {
      order: 2;
      margin: 0;
    }
  }

  &__right {
    text-align: right;
    width: 30%;
    padding-left: 2rem;

    @include media-breakpoint-down(lg) {
      order: 2;
      width: 50%;
    }

    @include media-breakpoint-down(sm) {
      order: 3;
      width: 100%;
      padding: 1rem 0 0;
      text-align: left;
    }
  }
}

.lch-360-btn {
  display: flex;
  height: 100%;

  @include media-breakpoint-down(lg) {
    margin-top: 1rem;
    height: auto;
  }

  &__text {
  }

  &__input {
    display: flex;
    justify-content: center;

    .v-linear-radio__line {

    }
  }

  &__radio {
    width: auto;
  }

  &__card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: .5rem;

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  &__button {
    display: none;

    @include media-breakpoint-down(lg) {
      display: block;
    }
  }
}
</style>
