import { mapActions, mapState } from 'vuex'
import { addClassesToBody, clearBodyClassesByList, getType, getUrlParams } from '@utils/helpers'

export default {
  data () {
    return {
      modePrefix: 'mode'
    }
  },

  mounted () {
    this.setModes()
  },

  computed: {
    ...mapState({
      currentModes: state => state.mode.current,
      allModes: state => state.mode.all
    })
  },

  watch: {
    currentModes () {
      this.updateModes()
    }
  },

  methods: {
    ...mapActions({
      setMode: 'mode/setMode'
    }),

    setModes () {
      const modesNames = getUrlParams()

      if (!modesNames || !modesNames.mode) return

      const modesType = getType(modesNames.mode)

      if (modesType === 'string') {
        modesNames.mode = [modesNames.mode]
      }

      modesNames.mode = this.handleCompoundModes(modesNames.mode)

      modesNames.mode.forEach(modeName => {
        this.setMode(modeName)
      })
    },

    updateModes () {
      clearBodyClassesByList(this.allModes, this.modePrefix)
      addClassesToBody(this.currentModes, this.modePrefix)
    },

    /**
     * Handles compound modifiers
     * @param {string[]} modes - modes from url
     * @returns {string[]}
     */
    handleCompoundModes (modes) {
      // mode frame - hide header and footer
      if (modes.includes('frame')) {
        modes = [
          ...modes,
          'hide-header',
          'hide-footer',
          'full-screen',
          'bg-transparent'
        ]
      }

      return modes
    }
  }
}
