<template>
  <div class="star-line">
    <div
      v-for="star in localVariant"
      :key="star.code"
      @mouseover="onMouseOver(star.code)"
      @mouseout="onMouseOut()"
      class="star-line__item"
    >
      <v-star
        :outlined="outlined"
        :size="size"
        :value="star.isActive"
        @input="onInput(star.code)"
      />
    </div>
  </div>
</template>

<script>
import { VStar } from '@components/base'

export default {
  name: 'StarLine',

  components: {
    VStar
  },

  props: {
    outlined: Boolean,
    size: String,
    value: [String, Number],
    variant: Array
  },

  data () {
    return {
      localVariant: this.variant
    }
  },

  computed: {
    currentLocalValue () {
      return this.localVariant.reduce((result, star) => {
        if (star.isActive) {
          result++
        }

        return result
      }, 0)
    }
  },

  mounted () {
    this.localVariant.forEach(variant => {
      if (variant.isActive === undefined) {
        this.$set(variant, 'isActive', false)
      }
    })
  },

  methods: {
    clearStars () {
      this.localVariant.forEach(star => {
        star.isActive = false
      })
    },

    fillStarsToId (starId) {
      let value = true

      this.localVariant.forEach(star => {
        star.isActive = value

        if (star.id === starId) {
          value = false
        }
      })
    },

    fillStarsToValue () {
      if (this.value) {
        this.fillStarsToId(this.variant[this.value - 1].id)
      } else {
        this.clearStars()
      }
    },

    onInput (starId) {
      this.fillStarsToId(starId)
      this.$emit('input', this.currentLocalValue)
    },

    onMouseOver (starId) {
      this.fillStarsToId(starId)
    },

    onMouseOut () {
      this.fillStarsToValue()
    }
  }
}
</script>

<style lang="scss">
.star-line {
  margin: -.25rem;

  &__item {
    display: inline-flex;
    padding: .25rem;
  }
}
</style>
