<template>
  <div
    v-click-outside="hideContent"
    class="v-menu"
  >
    <div
      ref="button"
      @click="showContent"
      class="v-menu__button"
    >
      <slot name="button"></slot>
    </div>

    <div
      ref="container"
      v-show="isActive"
      :class="contentClasses"
      @click="hideContent"
      class="v-menu__content"
    >
      <slot name="default"></slot>
    </div>
  </div>
</template>

<script>
import ClickOutside from '@directives/click-outside'
import { createPopper } from '@popperjs/core'
import { convertRemToPx } from '@utils/helpers'
import { Elevatable, Roundable } from '@mixins'

const POSITIONS = [
  'overlap-top',
  'overlap-top-left',
  'overlap-top-right'
]

export default {
  name: 'VMenu',

  directives: {
    ClickOutside
  },

  mixins: [
    Elevatable,
    Roundable
  ],

  props: {
    elevation: {
      type: [Number, String],
      default: 4
    },
    rounded: {
      type: [Boolean, String],
      default: true
    },
    position: {
      type: String,
      default: 'overlap-top',
      validator: (type) => POSITIONS.includes(type)
    },
    popperOptions: {
      type: [Object, null],
      default: null
    },
    readOnly: Boolean
  },

  data () {
    return {
      isActive: false,
      popper: {
        instance: null
      }
    }
  },

  computed: {
    localPopperOptions () {
      switch (this.position) {
        case 'overlap-top':
          return {
            placement: 'bottom-start',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: ({ reference }) => {
                    return [0, -reference.height]
                  }
                }
              }
            ]
          }
        case 'overlap-top-left':
          return {
            placement: 'bottom-start',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: ({ reference }) => [
                    -convertRemToPx(0.75),
                    -(reference.height + convertRemToPx(1))
                  ]
                }
              }
            ]
          }
        default:
          return {
            placement: 'bottom-start',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: ({ reference }) => [
                    0,
                    -(reference.height + convertRemToPx(0.5))
                  ]
                }
              }
            ]
          }
      }
    },
    contentClasses () {
      return {
        ...this.roundedClasses,
        ...this.elevationClasses
      }
    }
  },

  beforeDestroy () {
    this.destroyPopper()
  },

  methods: {
    showContent () {
      if (this.isActive || this.readOnly) return
      this.isActive = true
      this.updatePopper()
      this.$emit('show', this)
    },

    hideContent () {
      this.$nextTick(() => {
        if (!this.isActive) return
        this.isActive = false
        this.$emit('hide', this)
      })
    },

    createPopper () {
      this.$nextTick(() => {
        this.popper.instance = createPopper(
          this.$refs.button,
          this.$refs.container,
          this.popperOptions || this.localPopperOptions
        )
      })
    },

    destroyPopper () {
      if (!this.popper.instance) return
      this.popper.instance.destroy()
    },

    updatePopper () {
      this.popper.instance
        ? this.popper.instance.update()
        : this.createPopper()
    }
  }
}
</script>

<style lang="scss" scoped>
.v-menu {
  position: relative;
  display: flex;

  &__button {
    display: inline-flex;
    cursor: pointer;
  }

  &__content {
    position: absolute;
    z-index: 10;
    background-color: #FFFFFF;

    > *:first-child {
      border-top-right-radius: inherit;
      border-top-left-radius: inherit;
    }

    > *:last-child {
      border-bottom-right-radius: inherit;
      border-bottom-left-radius: inherit;
    }
  }
}
</style>
