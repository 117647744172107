import { getOffset } from '@utils/helpers'

/**
 * Get the position of the mouse/finger in the element
 * @param {MouseEvent | TouchEvent} e Trigger event
 * @param {HTMLDivElement} elem Container element
 * @returns {{x: number, y: number}} - position in the element
 */
export default function getPos (e, elem) {
  const event = 'targetTouches' in e ? e.targetTouches[0] : e
  const offset = getOffset(elem)
  return {
    x: event.pageX - offset.x,
    y: event.pageY - offset.y
  }
}
