/**
 * Checks if a variable contains some data.
 * @param {*} variable - The variable to be checked.
 * @returns {boolean} - Returns true if the variable contains some data, otherwise false.
 */
export default (variable) => {
  if (variable === null || variable === undefined || variable === '') {
    return false
  }
  if (Array.isArray(variable)) {
    return variable.length !== 0
  }
  if (typeof variable === 'object') {
    return Object.keys(variable).length !== 0
  }
  return true
}
