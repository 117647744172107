var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"dr-cheb",class:_vm.classes},[_c('div',{staticClass:"dr-cheb-count"},[_c('div',{staticClass:"dr-cheb-count__item"},[_c('v-avatar',{staticClass:"mr-8",attrs:{"color":"danger"}},[_vm._v(" "+_vm._s(_vm.answerLists[0].length)+" ")]),_c('b',{domProps:{"innerHTML":_vm._s(_vm.content.l_text)}})],1),_c('div',{staticClass:"dr-cheb-count__item dr-cheb-count__item_middle"},[_c('b',{domProps:{"innerHTML":_vm._s(_vm.content.m_text)}})]),_c('div',{staticClass:"dr-cheb-count__item"},[_c('b',{domProps:{"innerHTML":_vm._s(_vm.content.r_text )}}),_c('v-avatar',{staticClass:"ml-8",attrs:{"color":"success"}},[_vm._v(" "+_vm._s(_vm.answerLists[1].length)+" ")])],1)]),_c('div',{staticClass:"dr-cheb__content"},[_c('div',{staticClass:"dr-cheb__answer dr-cheb__answer_position_left"},[_c('draggable',{staticClass:"dr-cheb-list",attrs:{"value":_vm.answerLists[0],"force-fallback":true,"group":{
          name: 'left',
          put: !_vm.leftIsMax,
          pull: true
        },"data-group-name":_vm.content.placeholder,"tag":"ul","animation":"200","role":"list"},on:{"change":function($event){return _vm.changeAnswer($event, 0)}}},_vm._l((_vm.answerLists[0]),function(answerLeft){return _c('li',{key:answerLeft.code,staticClass:"dr-cheb-list__item",attrs:{"role":"listitem"}},[_c('div',{staticClass:"dr-cheb-list__text",domProps:{"innerHTML":_vm._s(answerLeft.text)}})])}),0)],1),_c('div',{ref:"question",staticClass:"dr-cheb__question"},[_c('draggable',{staticClass:"dr-cheb-list",attrs:{"force-fallback":true,"group":{
          name: 'question',
          put: true,
          pull: true
        },"value":_vm.questionsPC,"tag":"ul","animation":"200","role":"list"}},_vm._l((_vm.questionsPC),function(questionPC){return _c('li',{key:questionPC.code,staticClass:"dr-cheb-list__item",attrs:{"role":"listitem"}},[_c('button',{staticClass:"dr-cheb-list__button dr-cheb-list__button_left",attrs:{"role":"button"},on:{"click":function($event){return _vm.moveTo(0, questionPC)}}},[_c('v-icon-svg',{staticClass:"rotate-180",attrs:{"fill":"none","stroke":"currentColor","view-box":"0 0 10 18","width":"1rem","height":"1rem"}},[_c('svg-icon-chevron2')],1)],1),_c('button',{staticClass:"dr-cheb-list__button dr-cheb-list__button_right",attrs:{"role":"button"},on:{"click":function($event){return _vm.moveTo(1, questionPC)}}},[_c('v-icon-svg',{attrs:{"fill":"none","stroke":"currentColor","view-box":"0 0 10 18","width":"1rem","height":"1rem"}},[_c('svg-icon-chevron2')],1)],1),_c('div',{staticClass:"dr-cheb-list__text",domProps:{"innerHTML":_vm._s(questionPC.text)}})])}),0)],1),_c('div',{staticClass:"dr-cheb__answer dr-cheb__answer_position_right",style:({minHeight: `${_vm.minHeight}px`})},[_c('draggable',{staticClass:"dr-cheb-list",attrs:{"data-group-name":_vm.content.placeholder,"force-fallback":true,"group":{
          name: 'right',
          put: !_vm.rightIsMax,
          pull: true
        },"value":_vm.answerLists[1],"tag":"ul","animation":"200","role":"list"},on:{"change":function($event){return _vm.changeAnswer($event, 1)}}},_vm._l((_vm.answerLists[1]),function(answerRight){return _c('li',{key:answerRight.code,staticClass:"dr-cheb-list__item",attrs:{"role":"listitem"}},[_c('div',{staticClass:"dr-cheb-list__text",domProps:{"innerHTML":_vm._s(answerRight.text)}})])}),0)],1)]),_c('div',{staticClass:"dr-cheb__content dr-cheb__content_is_mobile"},[_c('div',{staticClass:"dr-cheb__question"},[_c('ul',{staticClass:"dr-cheb-list",attrs:{"role":"list"}},_vm._l((_vm.mobileQuestionList),function(questionMobile){return _c('li',{directives:[{name:"touch",rawName:"v-touch",value:({
            left: () => _vm.swipeHandler(0, questionMobile),
            right: () => _vm.swipeHandler(1, questionMobile)
          }),expression:"{\n            left: () => swipeHandler(0, questionMobile),\n            right: () => swipeHandler(1, questionMobile)\n          }"},{name:"drag",rawName:"v-drag",value:({
            left: () => _vm.swipeHandler(0, questionMobile),
            right: () => _vm.swipeHandler(1, questionMobile),
          }),expression:"{\n            left: () => swipeHandler(0, questionMobile),\n            right: () => swipeHandler(1, questionMobile),\n          }"}],key:`list-mobile__${questionMobile.code}`,staticClass:"dr-cheb-list__item",class:{
            'dr-cheb-list__item_move_left': questionMobile.direction === 0,
            'dr-cheb-list__item_move_right': questionMobile.direction === 1
          },attrs:{"role":"listitem"}},[_c('button',{staticClass:"dr-cheb-list__button dr-cheb-list__button_left",attrs:{"role":"button"},on:{"click":function($event){return _vm.moveTo(0, questionMobile)}}},[_c('v-icon-svg',{staticClass:"rotate-180",attrs:{"fill":"none","stroke":"currentColor","view-box":"0 0 10 18","width":"1rem","height":"1rem"}},[_c('svg-icon-chevron2')],1)],1),_c('button',{staticClass:"dr-cheb-list__button dr-cheb-list__button_right",attrs:{"role":"button"},on:{"click":function($event){return _vm.moveTo(1, questionMobile)}}},[_c('v-icon-svg',{attrs:{"fill":"none","stroke":"currentColor","view-box":"0 0 10 18","width":"1rem","height":"1rem"}},[_c('svg-icon-chevron2')],1)],1),_c('div',{staticClass:"dr-cheb-list__text",domProps:{"innerHTML":_vm._s(questionMobile.text)}})])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }